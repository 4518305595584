import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes, useParams, useNavigate, NavigateFunction, Navigate } from 'react-router-dom';
import { TopicDatabase } from './TopicDatabase';
import { TopicEditorView } from './ui/TopicEditor';
import { TopicPracticeView} from './ui/TopicPractice';
import { ConfirmationDialog } from './ui/Common';
import { TopicStatsView } from './ui/TopicStats';
import { browserLocalPersistence, getAuth, GoogleAuthProvider, onAuthStateChanged, setPersistence, signInWithPopup, signOut } from 'firebase/auth';
import { TopicOverview } from './ui/TopicsOverview';
import { AppUserState } from './Data';
import { enableIndexedDbPersistence, getFirestore } from 'firebase/firestore';
import registerServiceWorker, {unregister} from "./serviceWorker";

function Overview(props: {appState: AppUserState}) {
    const navigate = useNavigate();
    return <TopicOverview
                appState={props.appState}
                logout={logout}
                navigate={navigate}/>
}

function EditTopicById(props: {appState: AppUserState}) {
    const { topicId } = useParams();
    const navigate = useNavigate();

    if (!topicId) {
        return <p>There's no such topic!</p>
    }
    return <TopicEditorView
                topicId={topicId}
                appState={props.appState}
                logout={logout}
                navigate={navigate}/>
}

function PracticeTopicById(props: {appState: AppUserState}) {
    const { topicId } = useParams();
    const navigate = useNavigate();

    if (!topicId) {
        return <p>There's no such topic!</p>
    }
    return <TopicPracticeView
                topicId={topicId}
                appState={props.appState}
                logout={logout}
                navigate={navigate}/>
}

function DeleteTopicById(props: {appState: AppUserState}) : JSX.Element {
    const { topicId } = useParams();
    const navigate = useNavigate();

    if (!topicId) {
        return <p>There's no such topic!</p>
    }
    function deleteTopic() {
        if (!topicId) {
            return;
        }
        props.appState.topicDatabase.deleteTopic(topicId)
            .then(() => {
                navigate(`/`);
            });
    }
    function cancelDeleteTopic() {
        navigate(`/`);
    }
    return <ConfirmationDialog
              question={"Do you really want to delete the topic?"}
              answer_yes={deleteTopic}
              answer_no={cancelDeleteTopic}/>;
}

function StatsTopicById(props: {appState: AppUserState}) {
    const { topicId } = useParams();
    const navigate = useNavigate();

    if (!topicId) {
        return <p>There's no such topic!</p>
    }
    return <TopicStatsView
               topicId={topicId}
               appState={props.appState}
               logout={logout}
               navigate={navigate}/>
}

unregister();

registerServiceWorker();

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });
const app = TopicDatabase.createFirebase();
const auth = getAuth(app);
const db = getFirestore(app);
let userId: string | undefined = undefined;

onAuthStateChanged(auth, (user) => {
    if (user) {
        userId = user.uid;
    } else {
        userId = undefined
    }
});

function logout(navigate: NavigateFunction) {
    userId = undefined;
    const app = getAuth();
    signOut(app).then(() => {
        navigate("/reload");
        window.location.reload();
    })
}

async function getUserId() : Promise<string> {
    if (userId !== undefined) {
        return userId;
    }
    return signInWithPopup(auth, googleProvider)
        .catch((error) => {
            console.log(error);
            return undefined
        })
        .then((res) => {
            if (res === undefined) {
                return "";
            }
            return res.user.uid;
        });
}

enableIndexedDbPersistence(db, {forceOwnership: true})
    .catch((error) => {console.log(error)})
    .then(() => {
        setPersistence(auth, browserLocalPersistence)
            .then(() => {               
                getUserId()
                    .then((userId) => {
                        const appState = {
                            topicDatabase: new TopicDatabase(db, userId),
                        };
                        ReactDOM.render(
                            <React.StrictMode>
                                <BrowserRouter>
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={<Overview appState={appState} /> } />
                                        <Route
                                            path="/edit/:topicId"
                                            element={<EditTopicById appState={appState} />} />
                                        <Route
                                            path="/test/:topicId"
                                            element={<PracticeTopicById appState={appState} />} />
                                        <Route
                                            path="/delete/:topicId"
                                            element={<DeleteTopicById appState={appState} />} />
                                        <Route
                                            path="/stats/:topicId"
                                            element={<StatsTopicById appState={appState} />} />
                                        <Route
                                            path="/reload"
                                            element={ <Navigate to="/" /> }/>
                                    </Routes>
                                </BrowserRouter>
                            </React.StrictMode>,
                            document.getElementById('root')
                        );
                    
                        // If you want to start measuring performance in your app, pass a function
                        // to log results (for example: reportWebVitals(console.log))
                        // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
                        reportWebVitals();
                    }).catch((error) => {
                        console.log(error);
                    });
            });
    });