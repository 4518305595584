import {generateUniqueId} from "./Common";
import { TopicDatabase } from "./TopicDatabase";

export type TopicMetadata = {
    readonly id: string;
    name: string;
}

export type Topic = {
    readonly id: string;
    name: string;
    blocks: Block[];
};

export type Block = TextBlock | DefinitionBlock | TableBlock | ImageBlock | VocabularyBlock;
export type BlockType = "text" | "definition" | "table" | "image" | "vocabulary";

export type TextBlock = {
    readonly id: string;
    readonly type: "text";
    text: string
};

export type DefinitionBlock = {
    readonly id: string;
    readonly type: "definition";
    words: string[];
    definition: string;
};

export type TableBlock = {
    readonly id: string;
    readonly type: "table";
    title: string;
    columns: TableColumn[];
};

export type ImageBlock = {
    readonly id: string;
    readonly type: "image";
    title: string;
    image: RawImageData;
    imageMarkers: ImageMarker[];
};

export type RawImageData = {
    bytes: string,
    reference: string,
};

export type VocabularyBlock = {
    readonly id: string;
    readonly type: "vocabulary";
    title: string;
    vocabulary: Vocabulary[];
};

export type Vocabulary = {
    vocabularies: string[],
    translations: string[],
};

export function createDefaultBlock(blockType: BlockType): Block {
    switch (blockType) {
        case "text": return {
            id: generateUniqueId(),
            type: blockType,
            text: ""
        };
        case "definition": return {
            id: generateUniqueId(),
            type: blockType,
            words: [""],
            definition: ""
        };
        case "table": return {
            id: generateUniqueId(),
            type: blockType,
            title: "",
            columns: []
        };
        case "image": return {
            id: generateUniqueId(),
            type: blockType,
            title: "",
            image: { bytes: "", reference: ""},
            imageMarkers: []
        };
        case "vocabulary": return {
            id: generateUniqueId(),
            type: blockType,
            title: "",
            vocabulary: [],
        };
    }
}

export function verifyBlock(_: Block) : string {
    return "";
}

export type TableCellType = "text" | "image";

export type TableColumn = {
    type: TableCellType;
    name: string;
    cells: TableCell[];
};

export type TableCell = TextCell | ImageCell;

export type TextCell = {
    readonly id: string;
    readonly type: "text";
    values: string[];
};

export type ImageCell = {
    readonly id: string;
    readonly type: "image";
    image: RawImageData;
};

export function createDefaultCell(cellType: TableCellType): TableCell {
    switch (cellType) {
        case "text": return {
            id: generateUniqueId(),
            type: "text",
            values: [""],
        };
        case "image": return {
            id: generateUniqueId(),
            type: "image",
            image: { bytes: "", reference: ""},
        };
    }
}

export type Point = {
    x: number;
    y: number;
};

export type ImageMarker = {
    labels: string[];
    position: Point;
};

export type StatsEntry = {
    time: number;
    correctAnswers: number;
    incorrectAnswers: number;
}

export type AppUserState = {
    topicDatabase: TopicDatabase;
}