import React from "react";
import { NavigateFunction } from "react-router-dom";
import { MainView, MainViewProps, MainViewState } from "../App";
import { AppUserState, TopicMetadata } from "../Data";
import { ConfidenceRating } from "../TopicDatabase";

export interface TopicOverviewProps extends MainViewProps {}

export interface TopicOverviewState extends MainViewState {
    topics: TopicMetadata[];
    createTopicName: string;
}

export class TopicOverview extends MainView<TopicOverviewProps, TopicOverviewState> {
    componentDidMount() {
        this.props.appState.topicDatabase.recentTopics()
            .catch((error) : TopicMetadata[] => {
                this.showError("Fetching topic data failed with: " + error);
                return [];
            })
            .then(topics => {
                this.setState((initialState) => {
                    return {
                        ...initialState,
                        topics: topics,
                    };
                });
            });
    }

    initialState(): TopicOverviewState {
        return {
            topics: [],
            createTopicName: "",
        };
    }

    addTopic() {
        const topicName = this.state.createTopicName.trim();
        if (topicName.length === 0) {
            this.showError("Please specify a topic name.");
            return;
        }
        this.props.appState.topicDatabase.createTopic(this.state.createTopicName)
            .then((topicId) => {
                if (!topicId) {
                    this.showError('Failed to create topic.');
                    return;
                }
                this.props.navigate(`/edit/${topicId}`);
            })
    }

    updateCreateTopicName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState((initialState) => {
            return {
                ...initialState,
                createTopicName: event.target.value,
            };
        });
    }

    renderCreateTopicButton(enabled: boolean) {
        if (enabled) {
            return (
                <button
                    onClick={this.addTopic.bind(this)}
                    title="Create a new topic"
                    className="ActionButton">Create</button>
            );
        } else {
            return (
                <button
                    title="Create a new topic"
                    className="ActionButton">Create</button>
            );
        }
    }

    renderTopics() {
        if (this.state.topics.length === 0) {
            return (
                <div className="centerText">No topics.</div>
            );
        } else {
            return (
                <div>
                    {this.state.topics.map((topic: TopicMetadata) => {
                        return (
                            <TopicResultEntry topic={topic}
                                            key={topic.id}
                                            appState={this.props.appState}
                                            navigate={this.props.navigate} />
                        );
                    })}
                </div>
            );
        }
    }

    renderContentPage() {
        return (
            <div className="App">
                {this.renderTopics()}
                <div className="ActionBar">
                    <input
                        type="text"
                        value={this.state.createTopicName}
                        onChange={this.updateCreateTopicName.bind(this)}/>
                    {this.renderCreateTopicButton(this.state.createTopicName !== "")}
                </div>
            </div>
        );
    }
}

interface TopicResultEntryProps {
    topic: TopicMetadata;
    appState: AppUserState;
    navigate: NavigateFunction;
}

interface TopicResultEntryState {
    rating: ConfidenceRating;
}

class TopicResultEntry extends React.Component<TopicResultEntryProps, TopicResultEntryState> {
    constructor(props: TopicResultEntryProps) {
        super(props);
        this.state = {
            rating: ConfidenceRating.Beginner,
        }
    }

    componentDidMount() {
        this.props.appState.topicDatabase.getConfidenceRatingForTopic(this.props.topic.id)
        .then((rating) => {
            this.setState((inputState) => {
                return {
                    ...inputState,
                    rating: rating
                };
            });
        });
    }
        
    testTopic() {
        this.props.navigate(`/test/${this.props.topic.id}`);
    }

    editTopic() {
        this.props.navigate(`/edit/${this.props.topic.id}`);
    }

    showStats() {
        this.props.navigate(`/stats/${this.props.topic.id}`);
    }

    deleteTopic() {
        this.props.navigate(`/delete/${this.props.topic.id}`);
    }

    renderStatsConfidence() {
        switch (this.state.rating) {
            case ConfidenceRating.Beginner:
                return <img className="TextImage" src={require("../static/stars0.png")} alt="0 Stars" />
            case ConfidenceRating.Intermediate:
                return <img className="TextImage" src={require("../static/stars1.png")}  height="12" alt="1 Stars" />
            case ConfidenceRating.Expert:
                return <img className="TextImage" src={require("../static/stars2.png")}  alt="2 Stars" />
            case ConfidenceRating.SuperStar:
                return <img className="TextImage" src={require("../static/stars3.png")}  alt="3 Stars" />
        }
    }

    render() {
        return (
            <div className="Block">
                <span style={{lineHeight: "200%", paddingLeft: "10px"}}>{this.props.topic.name}</span>
                <div className="rightFlow">
                    <button
                        onClick={this.testTopic.bind(this)}
                        title="Practice the topic"
                        className="ActionButton">practice</button>
                    <button
                        onClick={this.editTopic.bind(this)}
                        title="Edit the content of the topic"
                        className="ActionButton">edit</button>
                    <button
                        onClick={this.showStats.bind(this)}
                        title="Show learning progress for the topic"
                        className="ActionButton">{this.renderStatsConfidence()}</button>
                    <button
                        onClick={this.deleteTopic.bind(this)}
                        title="Delete the topic"
                        className="ActionButton">x</button>
                </div>
                <div className="clearfix">&nbsp;</div>
            </div>
        );
    }
}