import React from "react";
import { DefinitionBlock, Block } from "../Data";
import {autoGrowTextArea, pickOne} from "../Common";
import {QuestionMeta, questionMetaDefaults} from "./Question";
import { TopicDatabase } from "../TopicDatabase";
import {cleanTextInput, MultiValueInput} from "./Common";

export interface DefinitionBlockViewProps {
    block: DefinitionBlock;
    updateBlock: (block: Block) => boolean;
}

export interface DefinitionBlockViewState {
    words: string[];
    definition: string;
}

export class DefinitionBlockView extends React.Component<DefinitionBlockViewProps, DefinitionBlockViewState> {
    constructor(props: DefinitionBlockViewProps) {
        super(props);
        this.state = {
            words: this.props.block.words,
            definition: this.props.block.definition,
        }
    }

    updateWord(words: string[]) {
        this.setState((initialState) => {
            return {
                ...initialState,
                words: words,
            };
        });
    }

    updateDefinition(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState((initialState) => {
            return {
                ...initialState,
                definition: event.target.value,
            };
        });
    }

    triggerBlockUpdate() {
        this.props.updateBlock({
            id: this.props.block.id,
            type: this.props.block.type,
            words: this.state.words.map(l => cleanTextInput(l)),
            definition: cleanTextInput(this.state.definition),
        });
    }

    render() {
        return (
            <div className="BlockContent">
                <div className="LabelTitle">Word</div>
                <MultiValueInput
                    values={this.state.words}
                    onUpdate={this.updateWord.bind(this)}
                    onBlur={this.triggerBlockUpdate.bind(this)}/>
                <div className="LabelTitle">Definition</div>
                <textarea
                    value={this.state.definition}
                    onChange={this.updateDefinition.bind(this)}
                    onBlur={this.triggerBlockUpdate.bind(this)}
                    onInput={autoGrowTextArea}/>
            </div>
        );
    }
}

export function createQuestionsForDefinition(block: DefinitionBlock,
                                             getConfidenceForBlock: (blockId: string,
                                                                     questionHint: string) => Promise<number>): Promise<QuestionMeta[]> {
    return getConfidenceForBlock(block.id, "")
        .then((confidence) => {
            if (TopicDatabase.IsBeginnerConfidence(confidence)) {
                return [{
                        ...questionMetaDefaults,
                        question: {
                            type: "text",
                            id: new Date().getTime(),
                            question: block.definition,
                            expectedAnswers: block.words
                        },
                        confidence: confidence,
                        blockId: block.id,
                    }];
            } else {
                return [{
                    ...questionMetaDefaults,
                    question: {
                        type: "text",
                        id: new Date().getTime(),
                        question: pickOne(block.words),
                        expectedAnswers: [block.definition]
                    },
                    confidence: confidence,
                    blockId: block.id,
                }];
            }
        });
}
