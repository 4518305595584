import React from "react";
import { MainView, MainViewProps, MainViewState } from "../App";
import {pickOne, hashCode, shuffle, specialCharacters} from "../Common";
import {AppUserState, Block, RawImageData, Topic} from "../Data";
import { StatsDatabase } from "../StatsDatabase";
import {ConfidenceRating, TopicDatabase} from "../TopicDatabase";
import { createQuestionsForDefinition } from "./Definition";
import { createQuestionsForImage, MarkedImageView } from "./Image";
import {
    cleanViewRenderType,
    ClozePart,
    ClozeQuestion,
    getExpectedAnswers,
    ImageQuestion,
    MultiInputQuestion, MultiInputQuestionTypes,
    MultipleChoiceQuestion,
    Question,
    QuestionFlags,
    QuestionMeta, renderValue,
    TextQuestion, ViewRenderType
} from "./Question";
import { createQuestionsForTable } from "./Table";
import { createQuestionsForText } from "./Text";
import {createQuestionsForVocabulary} from "./Vocabulary";
import {cleanTextInput, submitOnEnter} from "./Common";

interface TopicPracticeViewProps extends MainViewProps {
    topicId: string;
}

interface TopicPracticeViewState extends MainViewState {
    isConfigured: boolean;
    topic: Topic | undefined;
    excludedBlocks: string[];
    confidence: ConfidenceRating | undefined;
}

export class TopicPracticeView extends MainView<TopicPracticeViewProps, TopicPracticeViewState> {
    componentDidMount() {
        this.props.appState.topicDatabase.getTopicById(this.props.topicId)
            .then(topic => {
                if (!topic) {
                    this.showError("Failed to find Topic for " + this.props.topicId);
                } else {
                    this.setState((initialState) => {
                        return {
                            ...initialState,
                            topic: topic,
                        };
                    });
                }
            });
    }

    initialState(): TopicPracticeViewState {
        return {
            topic: undefined,
            isConfigured: false,
            excludedBlocks: [],
            confidence: undefined,
        };
    }

    handleBack() {
        this.props.navigate(`/`)
    }

    updateConfiguration(excludedBlocks: string[],
                        confidence: ConfidenceRating | undefined) {
        this.setState((internalState) => {
            return {
                ...internalState,
                isConfigured: true,
                confidence: confidence,
                excludedBlocks: excludedBlocks,
            };
        });
    }

    renderContent() {
        if (this.state.topic === undefined) {
            return (<div>Loading ...</div>);
        } else if (!this.state.isConfigured) {
            return (
                <TopicPracticeConfigurationView
                    topic={this.state.topic}
                    submitConfiguration={this.updateConfiguration.bind(this)}/>
            )
        } else {
            return (
                <TopicPracticeQuestionView
                    topic={this.state.topic}
                    excludeBlocks={this.state.excludedBlocks}
                    confidence={this.state.confidence}
                    appState={this.props.appState}
                    showError={this.showError.bind(this)} />
            )
        }
    }

    renderContentPage(): JSX.Element {
        if (this.state.topic === undefined) {
            return (<div>Loading ...</div>);
        } else {
            return (
                <div>
                    <h1>
                        <span
                            onClick={this.handleBack.bind(this)}
                            title="Go back"
                            role="button"
                            className="BackArrow">&lt;&lt;
                        </span>
                        {this.state.topic.name}
                    </h1>
                    {this.renderContent()}
                </div>
            );
        }
    }
}

interface TopicPracticeConfigurationViewProps {
    topic: Topic;
    submitConfiguration: (excludedBlocks: string[], confidence: ConfidenceRating | undefined) => void;
}

interface TopicPracticeConfigurationViewState {
    excludedBlocks: string[];
    confidence: ConfidenceRating | undefined;
}

export class TopicPracticeConfigurationView extends React.Component<TopicPracticeConfigurationViewProps, TopicPracticeConfigurationViewState> {
    constructor(props: TopicPracticeConfigurationViewProps) {
        super(props);
        this.state = {
            excludedBlocks: [],
            confidence: undefined,
        }
    }

    getConfidenceRatingString(confidence: ConfidenceRating | undefined) : string {
        if (confidence === undefined) {
            return "Default";
        } else {
            switch (confidence) {
                case ConfidenceRating.Beginner:
                    return "Easy";
                case ConfidenceRating.Intermediate:
                    return "Medium";
                case ConfidenceRating.Expert:
                    return "Hard";
                case ConfidenceRating.SuperStar:
                    return "Epic";
            }
        }
    }

    getConfidenceRatingFromString(string: string): ConfidenceRating | undefined {
        if (string === "Easy") {
            return ConfidenceRating.Beginner;
        } else if (string === "Medium") {
            return ConfidenceRating.Intermediate;
        } else if (string === "Hard") {
            return ConfidenceRating.Expert;
        } else if (string === "Epic") {
            return ConfidenceRating.SuperStar;
        } else {
            return undefined;
        }
    }

    updateConfidence(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState((inputState) => {
            return {
                ...inputState,
                confidence: this.getConfidenceRatingFromString(event.target.value),
            };
        });
    }

    renderConfidenceRating() {
        return (
            <div>
                <label>Difficulty:</label>
                <select value={this.getConfidenceRatingString(this.state.confidence)}
                        onChange={this.updateConfidence.bind(this)}>
                    <option value={this.getConfidenceRatingString(undefined)}>{this.getConfidenceRatingString(undefined)}</option>
                    <option value={this.getConfidenceRatingString(ConfidenceRating.Beginner)}>{this.getConfidenceRatingString(ConfidenceRating.Beginner)}</option>
                    <option value={this.getConfidenceRatingString(ConfidenceRating.Intermediate)}>{this.getConfidenceRatingString(ConfidenceRating.Intermediate)}</option>
                    <option value={this.getConfidenceRatingString(ConfidenceRating.Expert)}>{this.getConfidenceRatingString(ConfidenceRating.Expert)}</option>
                    <option value={this.getConfidenceRatingString(ConfidenceRating.SuperStar)}>{this.getConfidenceRatingString(ConfidenceRating.SuperStar)}</option>
                </select>
            </div>
        );
    }

    isBlockExcluded(blockId: string) {
        return this.state.excludedBlocks.includes(blockId);
    }

    changeBlockExcluded(blockId: string, exclude: boolean) {
        let updatedExcludedBlocks = this.state.excludedBlocks;
        if (exclude) {
            updatedExcludedBlocks.push(blockId);
        } else {
            updatedExcludedBlocks = this.state.excludedBlocks.filter(block => block !== blockId);
        }
        this.setState((initialState) => {
            return {
                ...initialState,
                excludedBlocks: updatedExcludedBlocks,
            };
        });
    }

    blockToTitle(block: Block) : string {
        switch (block.type) {
            case "text":
                return block.text.substring(0, 50);
            case "definition":
                return pickOne(block.words);
            case "vocabulary":
            case "table":
            case "image":
                return block.title;
        }
    }

    excludeAllBlocks() {
        this.setState((initialState) => {
            return {
                ...initialState,
                excludedBlocks: this.props.topic.blocks.map(b => b.id),
            };
        });
    }

    excludeNoBlocks() {
        this.setState((initialState) => {
            return {
                ...initialState,
                excludedBlocks: [],
            };
        });
    }

    renderBlockSelection(blocks: Block[]) {
        return (
            <div>
                <label>Practice:</label>
                <button
                    onClick={this.excludeNoBlocks.bind(this)}
                    title="Select all"
                    className="ActionButton">
                        <input type="checkbox" checked={true} readOnly={true}/>
                </button>
                <button
                    onClick={this.excludeAllBlocks.bind(this)}
                    title="Deselect all"
                    className="ActionButton">
                    <input type="checkbox" checked={false} readOnly={true}/>
                </button>
                {blocks.map((block) => {
                    return (
                        <div key={block.id}>
                            <input
                                type="checkbox"
                                checked={!this.isBlockExcluded(block.id)}
                                onChange={this.changeBlockExcluded.bind(this, block.id, !this.isBlockExcluded(block.id))}
                            />
                            <span>{this.blockToTitle(block)}</span>
                        </div>
                    );
                })}
            </div>
        );
    }

    submit() {
        this.props.submitConfiguration(this.state.excludedBlocks, this.state.confidence);
    }

    render(): JSX.Element {
        return (
            <div className="Block">
                <div className="BlockContent">
                    {this.renderConfidenceRating()}
                    {this.renderBlockSelection(this.props.topic.blocks)}
                    <button
                        className="ActionButton"
                        title="Start practicing"
                        onClick={this.submit.bind(this)}>Practice</button>
                </div>
            </div>
        );
    }
}

interface TopicPracticeQuestionViewProps {
    topic: Topic;
    excludeBlocks: string[];
    confidence: ConfidenceRating | undefined;
    appState: AppUserState;
    showError: (error: string) => void;
}

interface TopicPracticeQuestionViewState {
    question: QuestionMeta | undefined;
    successRate: {
        correct: number,
        incorrect: number,
    }
}

export class TopicPracticeQuestionView extends React.Component<TopicPracticeQuestionViewProps, TopicPracticeQuestionViewState> {
    practiceHandler: TopicPracticeHandler;

    componentDidMount() {
        this.practiceHandler.nextQuestion()
            .then((question) => {
                this.setState((initialState) => {
                    return {
                        ...initialState,
                        question: question,
                    };
                });
            });
    }

    constructor(props: TopicPracticeQuestionViewProps) {
        super(props);
        this.state = {
            question: undefined,
            successRate: {
                correct: 0,
                incorrect: 0,
            },
        }
        this.practiceHandler = new TopicPracticeHandler(
            this.props.topic,
            this.props.excludeBlocks,
            this.props.confidence,
            this.props.appState.topicDatabase,
            this.props.showError);
    }

    renderSpecialCharacters(question: QuestionMeta) {
        if (question.flags.includes(QuestionFlags.RENDER_SPECIAL_CHARACTERS)) {
            return (
                <div className="centerText">{specialCharacters.join(" ")}</div>
            );
        }
        return "";
    }

    notifyAnswer(correct: boolean) {
        if (this.practiceHandler) {
            this.practiceHandler.updateQuestionConfidence(correct);
        }
        StatsDatabase.getInstance().updateStatsForTopic(this.props.topic.id, correct);
        this.setState((initialState) => {
            return {
                ...initialState,
                successRate: {
                    correct: correct ? initialState.successRate.correct + 1 : initialState.successRate.correct,
                    incorrect: correct ? initialState.successRate.incorrect : initialState.successRate.incorrect + 1,
                },
            };
        });
    }

    nextQuestion() {
        if (this.practiceHandler) {
            this.practiceHandler.nextQuestion()
                .then((question) => {
                    this.setState((initialState) => {
                        return {
                            ...initialState,
                            question: question,
                        };
                    });
                });
        }
    }

    renderSuccessRateBar(count: number, className: string) {
        if (count <= 0) {
            return;
        }
        return (<div className={className} style={{flexGrow: count}}>{count}</div>);
    }

    renderSuccessRate(successRate: {
        correct: number,
        incorrect: number
    }) {
        return (
            <div className="successRateContainer">
                {this.renderSuccessRateBar(successRate.correct, "successRateCorrect")}
                {this.renderSuccessRateBar(successRate.incorrect, "successRateIncorrect")}
            </div>
        );
    }

    render(): JSX.Element {
        if (!this.state.question) {
            return (<div>Loading Questions.</div>);
        } else {
            return <div>
                {this.renderSuccessRate(this.state.successRate)}
                {this.renderSpecialCharacters(this.state.question)}
                <div className="Block">
                    <QuestionView question={this.state.question.question}
                                  notifyAnswer={this.notifyAnswer.bind(this)}
                                  nextQuestion={this.nextQuestion.bind(this)}
                                  showError={this.props.showError} />
                </div>
            </div>
        }
    }
}

interface QuestionViewProps {
    question: Question;
    notifyAnswer: (correct: boolean) => void;
    nextQuestion: () => void;
    showError: (error: string) => void;
}

interface QuestionViewState {
    showCorrection: boolean;
}

class QuestionView extends React.Component<QuestionViewProps, QuestionViewState> {

    private questionView: TextQuestionView | MultipleChoiceQuestionView | MultiInputQuestionView | ClozeQuestionView | ImageQuestionView | undefined;

    constructor(props: QuestionViewProps) {
        super(props);
        this.state = {
            showCorrection: false,
        };
    }

    public correctAnswer(): boolean {
        if (this.questionView === undefined) {
            return false;
        }
        return this.questionView.correctAnswer();
    }

    setTextQuestionViewRef(questionView: TextQuestionView) {
        this.questionView = questionView;
    }

    setMultipleChoiceQuestionViewRef(questionView: MultipleChoiceQuestionView) {
        this.questionView = questionView;
    }

    setMultiInputQuestionViewRef(questionView: MultiInputQuestionView) {
        this.questionView = questionView;
    }

    setClozeQuestionViewRef(questionView: ClozeQuestionView) {
        this.questionView = questionView;
    }

    setImageQuestionViewRef(questionView: ImageQuestionView) {
        this.questionView = questionView;
    }

    renderQuestion(question: Question) {
        switch (question.type) {
            case "text":
                return (<TextQuestionView question={question}
                                          showCorrection={this.state.showCorrection}
                                          submitAnswer={this.submitAnswer.bind(this)}
                                          ref={this.setTextQuestionViewRef.bind(this)} />);
            case "multipleChoice":
                return (<MultipleChoiceQuestionView question={question}
                                                    showCorrection={this.state.showCorrection}
                                                    ref={this.setMultipleChoiceQuestionViewRef.bind(this)} />);
            case "multiInput":
                return (<MultiInputQuestionView question={question}
                                                showCorrection={this.state.showCorrection}
                                                showError={this.props.showError}
                                                submitAnswer={this.submitAnswer.bind(this)}
                                                ref={this.setMultiInputQuestionViewRef.bind(this)} />);
            case "cloze":
                return (<ClozeQuestionView question={question}
                                           showCorrection={this.state.showCorrection}
                                           submitAnswer={this.submitAnswer.bind(this)}
                                           ref={this.setClozeQuestionViewRef.bind(this)} />);
            case "image":
                return (<ImageQuestionView question={question}
                                           showCorrection={this.state.showCorrection}
                                           submitAnswer={this.submitAnswer.bind(this)}
                                           showError={this.props.showError}
                                           ref={this.setImageQuestionViewRef.bind(this)} />);
        }
    }

    submitAnswer() {
        if (!this.correctAnswer()) {
            this.setState((initialState) => {
                return {
                    ...initialState,
                    showCorrection: true,
                };
            });
            this.props.notifyAnswer(false);
        } else {
            this.props.notifyAnswer(true);
            this.nextQuestion();
        }
    }

    nextQuestion() {
        this.setState((_) => {
            return {
                showCorrection: false,
            };
        });
        this.props.nextQuestion();
    }

    render() {
        if (this.state.showCorrection) {
            return (
                <div className="BlockContent">
                    {this.renderQuestion(this.props.question)}
                    <button
                        className="ActionButton"
                        title="Show next question."
                        onClick={this.nextQuestion.bind(this)}>Next</button>
                </div>
            );
        } else {
            return (
                <div className="BlockContent">
                    {this.renderQuestion(this.props.question)}
                    <button
                        className="ActionButton"
                        title="Check your answer."
                        onClick={this.submitAnswer.bind(this)}>Check</button>
                </div>
            );
        }
    }
}

interface TextQuestionViewProps {
    question: TextQuestion;

    showCorrection: boolean;

    submitAnswer: () => void;
}

interface TextQuestionViewState {
    answer: string;
}

class TextQuestionView extends React.Component<TextQuestionViewProps, TextQuestionViewState> {
    constructor(props: TextQuestionViewProps) {
        super(props);
        this.state = this.initState();
    }


    private initState() {
        return {
            answer: "",
        };
    }

    componentDidUpdate(prevPros: TextQuestionViewProps) {
        if (prevPros.question !== this.props.question) {
            this.setState(() => {
                return this.initState();
            });
        }
    }

    correctAnswer(): boolean {
        return IsCorrectAnswer(this.props.question.expectedAnswers, this.state.answer);
    }

    render() : JSX.Element {
        if (this.props.showCorrection) {
            return renderViewRenderTypeCorrection(
                this.props.question.question,
                this.props.question.expectedAnswers,
                this.state.answer);
        } else {
            return renderTextQuestion(
                this.props.question.question,
                this.state.answer,
                this.updateAnswer.bind(this),
                true,
                this.props.submitAnswer.bind(this));
        }
    }

    updateAnswer(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState((initialState) => {
            return {
                ...initialState,
                answer: event.target.value,
            };
        });
    }
}

interface MultipleChoiceQuestionViewProps {
    question: MultipleChoiceQuestion;

    showCorrection: boolean;
}

interface MultipleChoiceQuestionViewState {
    answer: ViewRenderType;
}

class MultipleChoiceQuestionView extends React.Component<MultipleChoiceQuestionViewProps, MultipleChoiceQuestionViewState> {
    constructor(props: MultipleChoiceQuestionViewProps) {
        super(props);
        this.state = this.initState();
    }

    private initState() {
        let answer: ViewRenderType;
        if (typeof this.props.question.expectedAnswer === "string") {
            answer = ""
        } else {
            answer = { bytes: "", reference: ""}
        }
        return {
            answer: answer,
        };
    }

    componentDidUpdate(prevPros: MultipleChoiceQuestionViewProps) {
        if (prevPros.question !== this.props.question) {
            this.setState(() => {
                return this.initState();
            });
        }
    }

    correctAnswer(): boolean {
        return IsCorrectAnswer([this.props.question.expectedAnswer], this.state.answer);
    }

    render() : JSX.Element {
        if (this.props.showCorrection) {
            return renderViewRenderTypeCorrection(
                this.props.question.question,
                [this.props.question.expectedAnswer],
                this.state.answer);
        } else {
            return renderOneOfQuestion(
                this.props.question.question,
                this.props.question.possibleAnswers,
                this.state.answer,
                this.updateAnswer.bind(this));
        }
    }

    updateAnswer(event: React.ChangeEvent<HTMLInputElement>) {
        const selectedAnswer = getSelectedAnswer(this.props.question.possibleAnswers, event.target.value);
        if (selectedAnswer === undefined) {
            return;
        }
        this.setState((initialState) => {
            return {
                ...initialState,
                answer: selectedAnswer,
            };
        });
    }
}

interface MultiInputQuestionViewProps {
    question: MultiInputQuestion;

    showCorrection: boolean;

    showError: (error: string) => void;

    submitAnswer: () => void;
}

interface MultiInputQuestionViewState {}

class MultiInputQuestionView extends React.Component<MultiInputQuestionViewProps, MultiInputQuestionViewState> {

    private questionViews: (TextQuestionView | MultipleChoiceQuestionView | undefined) [] = [];


    constructor(props: MultiInputQuestionViewProps) {
        super(props);
        this.initState();
    }

    private initState() {
        this.questionViews = this.props.question.questions.map(() => { return undefined; });
    }

    componentDidUpdate(prevPros: MultiInputQuestionViewProps) {
        if (prevPros.question !== this.props.question) {
            this.initState();
        }
    }

    setTextQuestionViewRef(index: number, questionView: TextQuestionView) {
        this.questionViews[index] = questionView;
    }

    setMultipleChoiceQuestionViewRef(index: number, questionView: MultipleChoiceQuestionView) {
        this.questionViews[index] = questionView;
    }

    renderMultiInputQuestion(question: MultiInputQuestionTypes, index: number) : JSX.Element {
        switch (question.type) {
            case "text":
                return (<TextQuestionView question={question}
                                          submitAnswer={this.props.submitAnswer}
                                          showCorrection={this.props.showCorrection}
                                          ref={this.setTextQuestionViewRef.bind(this, index)}/>);
            case "multipleChoice":
                return (<MultipleChoiceQuestionView question={question}
                                                    showCorrection={this.props.showCorrection}
                                                    ref={this.setMultipleChoiceQuestionViewRef.bind(this, index)} />);
        }
    }

    correctAnswer(): boolean {
        return this.questionViews.map((questionView) => {
            return questionView?.correctAnswer();
        }).every(v => v);
    }

    render() : JSX.Element {
        return (
            <div>
                {this.props.question.title}
                {this.props.question.questions.map((question, index) => (
                    <div className="Block" key={`multi_input_${question.id}`}>
                        {this.renderMultiInputQuestion(question, index)}
                    </div>
                ))}
            </div>
        );
    }
}

interface ClozeQuestionViewProps {
    question: ClozeQuestion;

    showCorrection: boolean;

    submitAnswer: () => void;
}

interface ClozeQuestionViewState {
    answers: string[];
}

class ClozeQuestionView extends React.Component<ClozeQuestionViewProps, ClozeQuestionViewState> {
    constructor(props: ClozeQuestionViewProps) {
        super(props);
        this.state = this.initState();
    }

    private initState() {
        return {
            answers: this.getBlanks().map(() => ""),
        };
    }

    componentDidUpdate(prevPros: ClozeQuestionViewProps) {
        if (prevPros.question !== this.props.question) {
            this.setState(() => {
                return this.initState();
            });
        }
    }

    correctAnswer(): boolean {
        return this.getBlanks().map((v, i) => cleanTextInput(this.state.answers[i]) === v.text).every(v => v);
    }

    updateAnswer(answerIndex: number, event: React.ChangeEvent<HTMLInputElement>) {
        this.setState((initialState) => {
            initialState.answers[answerIndex] = event.target.value;
            return initialState;
        });
    }

    render() : JSX.Element {
        const views = [];
        const numBlanks = this.props.question.parts.reduce((numAnswers, part) => {
            if (part.type === "blank") {
                return numAnswers + 1;
            }
            return numAnswers;
        }, 0);
        let answerIndex = 0;
        for (let i = 0; i < this.props.question.parts.length; i++) {
            const part = this.props.question.parts[i];
            switch (part.type) {
                case "text":
                    views.push(renderText(part.text));
                    break;
                case "blank":
                    if (this.props.showCorrection) {
                        views.push(renderCorrection(
                            [part.text],
                            this.state.answers[answerIndex]));
                    } else {
                        views.push(
                            renderTextInput(
                                this.state.answers[answerIndex],
                                this.updateAnswer.bind(this, answerIndex),
                                answerIndex === 0,
                                answerIndex + 1 === numBlanks ? this.props.submitAnswer.bind(this) : undefined));
                    }
                    answerIndex++;
            }
        }

        return combineRendering(this.props.showCorrection ? "correction" : "question", views, true)
    }

    private getBlanks(): ClozePart[] {
        return this.props.question.parts.filter(v => v.type === "blank");
    }
}

interface ImageQuestionViewProps {
    question: ImageQuestion;
    showError: (error: string) => void;

    showCorrection: boolean;

    submitAnswer: () => void;
}

interface ImageQuestionViewState {
    answer: string;
}

class ImageQuestionView extends React.Component<ImageQuestionViewProps, ImageQuestionViewState> {
    constructor(props: ImageQuestionViewProps) {
        super(props);
        this.state = this.initState();
    }

    private initState() {
        return {
            answer: "",
        };
    }

    componentDidUpdate(prevPros: ImageQuestionViewProps) {
        if (prevPros.question !== this.props.question) {
            this.setState(() => {
                return this.initState();
            });
        }
    }

    correctAnswer(): boolean {
        return IsCorrectAnswer(getExpectedAnswers(this.props.question.question), this.state.answer);
    }

    updateAnswer(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState((initialState) => {
            return {
                ...initialState,
                answer: event.target.value,
            };
        });
    }

    render() : JSX.Element {
        let view;
        if (this.props.showCorrection) {
            view = renderViewRenderTypeCorrection(
                this.props.question.question.question,
                getExpectedAnswers(this.props.question.question),
                this.state.answer);
        } else {
            switch (this.props.question.question.type) {
                case "text":
                    view = renderTextQuestion(
                        this.props.question.question.question,
                        this.state.answer,
                        this.updateAnswer.bind(this),
                        true,
                        this.props.submitAnswer.bind(this));
                    break;
                case "multipleChoice":
                    view = renderOneOfQuestion(
                        this.props.question.question.question,
                        this.props.question.question.possibleAnswers,
                        this.state.answer,
                        this.updateAnswer.bind(this),
                        this.props.submitAnswer.bind(this))
                    break;
            }
        }
        return combineRendering(this.props.showCorrection ? "correction" : "question", [this.renderImageInformation(), view], false);
    }

    renderImageInformation() {
        const imageMarker = {
            position: this.props.question.position,
            labels: [this.props.question.question.question as string],
        }
        return <MarkedImageView image={this.props.question.image}
                                markers={[imageMarker]}
                                showError={this.props.showError.bind(this)} />
    }
}

function renderText(text: string) : JSX.Element {
    return <span>{text}</span>;
}

function renderViewRenderTypeCorrection(question: string | JSX.Element,
                                        expectedAnswers: ViewRenderType[],
                                        currentAnswer: ViewRenderType) : JSX.Element {
    return <div>
            {question}
            {renderCorrection(expectedAnswers, currentAnswer)}
        </div>;
}

function renderTextInput(currentAnswer: string,
                         updateAnswer: (event: React.ChangeEvent<HTMLInputElement>) => void,
                         setFocus: boolean,
                         onEnter?: () => void) : JSX.Element {
    return <input
                type="text"
                value={currentAnswer}
                onChange={updateAnswer}
                onKeyDown={(e) => submitOnEnter(e, onEnter)}
                autoFocus={setFocus}/>;
}

function renderTextQuestion(question: string | JSX.Element,
                            currentAnswer: string,
                            updateAnswer: (event: React.ChangeEvent<HTMLInputElement>) => void,
                            setFocus: boolean,
                            onEnter?: () => void) : JSX.Element {
    return <div key={hashCode(question.toString())}>
            <label>{question}</label>
            {renderTextInput(currentAnswer, updateAnswer, setFocus, onEnter)}
        </div>;
}

function valueKey(value: ViewRenderType) : string {
    if (typeof value === "string") {
        return value as string;
    } else {
        const imageData = value as RawImageData;
        return hashCode(imageData.bytes);
    }
}

function getSelectedAnswer(possibleAnswers: ViewRenderType[],
                           answer: string) : ViewRenderType | undefined {
    const matchingAnswers = possibleAnswers.filter(function(possibleAnswer) {
       return valueKey(possibleAnswer) === answer;
    })
    if (matchingAnswers.length === 1) {
        return matchingAnswers[0];
    } else {
        return undefined;
    }
}

function renderOneOfQuestion(question: string | JSX.Element,
                             possibleAnswers: ViewRenderType[],
                             currentAnswer: ViewRenderType,
                             updateAnswer: (event: React.ChangeEvent<HTMLInputElement>) => void,
                             onEnter?: () => void) : JSX.Element {
    return <div>
        <label>{question}</label>
        {possibleAnswers.map(function(possibleAnswer, index) {
            return (
                <div key={`${hashCode(question.toString())}_${index}`}>
                    <label>
                        <input type="radio"
                               value={valueKey(possibleAnswer)}
                               checked={valueKey(currentAnswer) === valueKey(possibleAnswer)}
                               name={hashCode(question.toString())}
                               onChange={updateAnswer}
                               onKeyDown={(e) => submitOnEnter(e, onEnter)}/>
                        {renderValue(possibleAnswer)}
                    </label>
                </div>
            );
        })}
    </div>;
}

function combineRendering(id: string, renderViews: JSX.Element[], inline: boolean) : JSX.Element {
    if (inline) {
        return (
            <div>
                {renderViews.map((renderView, i) => (
                    <span key={id + "_" + i}>{renderView}</span>
                ))}
            </div>);
    } else {
        return (
            <div>
                {renderViews.map((renderView, i) => (
                    <div key={id + "_" + i}>{renderView}</div>
                ))}
            </div>);
    }
}

function IsCorrectAnswer(expectedAnswers: ViewRenderType[], actualAnswer: ViewRenderType) {
    return expectedAnswers.some((expectedAnswer) => {
        return cleanViewRenderType(actualAnswer) === expectedAnswer;
    });
}

function renderCorrection(expectedAnswers: ViewRenderType[], actualAnswer: ViewRenderType) : JSX.Element {
    const cleanedActualAnswer = cleanViewRenderType(actualAnswer);
    const correct = IsCorrectAnswer(expectedAnswers, cleanedActualAnswer)
    if (correct) {
        return (
            <div>
                <div className="Answer"><div className="correctAnswer">{renderValue(cleanedActualAnswer)}</div></div>
            </div>
        )
    } else {
        return (
            <CorrectionView
                expectedAnswers={expectedAnswers}
                actualAnswer={actualAnswer}/>
        )
    }
}

export interface CorrectionViewProps {
    expectedAnswers: ViewRenderType[];
    actualAnswer: ViewRenderType;
}

enum CorrectionState {
    Default = 1,
    Incorrect,
    Correct,
}

export interface CorrectionViewState {
    corrections: {
        word: string,
        state: CorrectionState,
    }[];
}

class CorrectionView extends React.Component<CorrectionViewProps, CorrectionViewState> {
    constructor(props: CorrectionViewProps) {
        super(props);
        let corrections: {
            word: string,
            state: CorrectionState,
        }[] = [];
        if (typeof this.props.expectedAnswers[0] === "string") {
            corrections = [{
                word: "",
                state: CorrectionState.Default,
            }, {
                word: "",
                state: CorrectionState.Default,
            }, {
                word: "",
                state: CorrectionState.Default,
            }]
        }
        this.state = {
            corrections: corrections,
        };
    }

    updateCorrection(index: number, event: React.ChangeEvent<HTMLInputElement>) {
        this.setState((inputState) => {
            const updatedCorrections = inputState.corrections;
            updatedCorrections[index].word = event.target.value;
            if (this.props.expectedAnswers.some((expectedAnswer) => { return cleanViewRenderType(updatedCorrections[index].word) === cleanViewRenderType(expectedAnswer)})) {
                updatedCorrections[index].state = CorrectionState.Correct;
            } else {
                updatedCorrections[index].state = CorrectionState.Incorrect;
            }
            return {
                ...inputState,
                corrections: updatedCorrections,
            };
        });
    }

    getClassNameForCorrection(index: number) : string {
        switch (this.state.corrections[index].state) {
            case CorrectionState.Correct:
                return "correctAnswer"
            case CorrectionState.Incorrect:
                return "incorrectAnswer"
            case CorrectionState.Default:
                return ""
        }
    }

    render() {
        return (
            <div>
                <div className="Answer"><div className="incorrectAnswer">{renderValue(this.props.actualAnswer)}</div></div>
                {this.props.expectedAnswers.map((expectedAnswer, index) => {
                    return (
                        <div key={`expectedAnswer_${index}`} className="Answer"><div className="correctAnswer">{renderValue(expectedAnswer)}</div></div>
                    )}
                )}
                {this.state.corrections.map((correction, index) => {
                    return (
                        <div key={`correction_${index}`}>
                            <input
                                type="text"
                                value={correction.word}
                                className={this.getClassNameForCorrection(index)}
                                onChange={this.updateCorrection.bind(this, index)}
                                autoFocus={index === 0}/>
                        </div>
                    );
                })}
            </div>
        )
    }
}

class TopicPracticeHandler {
    private topic: Topic;
    private topicDatabase: TopicDatabase;
    private readonly showError: (error: string) => void;

    private questionMeta: QuestionMeta[];
    private currentQuestionIndex: number;
    private readonly excludeBlocks: string[];
    private readonly confidence: ConfidenceRating | undefined;
    
    constructor(topic: Topic,
                excludeBlocks: string[],
                confidence: ConfidenceRating | undefined,
                topicDatabase: TopicDatabase,
                showError: (error: string) => void) {
        this.topic = topic;
        this.excludeBlocks = excludeBlocks;
        this.confidence = confidence;
        this.topicDatabase = topicDatabase;
        this.showError = showError;
        this.questionMeta = [];
        this.currentQuestionIndex = -1;
    }

    async nextQuestion() : Promise<QuestionMeta | undefined> {
        ++this.currentQuestionIndex;
        if (this.currentQuestionIndex >= this.questionMeta.length) {
            return this.createQuestions()
                .then((questionMeta) => {
                    if (questionMeta.length === 0) {
                        return undefined;
                    }
                    this.questionMeta = questionMeta;
                    this.currentQuestionIndex = -1;
                    return this.nextQuestion();
                });
        } else {
            const question = this.currentQuestion();
            if (this.confidence === undefined) {
                // Select all questions with a confidence lower than 0.5 and some chance of picking a higher confident question.
                const confidenceSelectionThreshold = 0.5 + Math.random();
                if (question.confidence > confidenceSelectionThreshold) {
                    return this.nextQuestion();
                }
            }
            return question;
        }
    }

    updateQuestionConfidence(correct: boolean) {
        const question = this.currentQuestion();
        question.confidence = this.topicDatabase.updateConfidenceForBlock(
            this.topic.id, question.blockId, question.questionHint, question.confidence, correct);
    }

    private currentQuestion() : QuestionMeta {
        if (this.currentQuestionIndex < 0 || this.currentQuestionIndex >= this.questionMeta.length) {
            this.showError("No current questions index found.");
            throw new Error("No current questions index found.");
        }
        return this.questionMeta[this.currentQuestionIndex]
    }

    private async createQuestions() : Promise<QuestionMeta[]> {
        const questions = this.topic.blocks
            .filter((block: Block) => {
                return this.excludeBlocks.indexOf(block.id) === -1;
            })
            .map((block: Block) => {
                return this.createQuestionsForBlock(block);
            });

        return Promise.all(questions)
            .then((state) => {
                const nextQuestionMeta: QuestionMeta[] = state.flat();
                const questionMeta = shuffle(nextQuestionMeta)

                if (questionMeta.length === 0) {
                    this.showError("No questions found.");
                }

                return questionMeta;
            }); 
    }

    private async createQuestionsForBlock(block: Block) : Promise<QuestionMeta[]> {
        switch (block.type) {
            case "definition":
                return createQuestionsForDefinition(block, this.getConfidenceForBlock.bind(this));
            case "table":
                return createQuestionsForTable(block, this.getConfidenceForBlock.bind(this));
            case "text":
                return createQuestionsForText(block, this.getConfidenceForBlock.bind(this));
            case "image":
                return createQuestionsForImage(block, this.getConfidenceForBlock.bind(this));
            case "vocabulary":
                return createQuestionsForVocabulary(block, this.getConfidenceForBlock.bind(this));
        }
        return []
    }

    private async getConfidenceForBlock(blockId: string,
                                        questionHint: string) : Promise<number> {
        if (this.confidence !== undefined) {
            return TopicDatabase.getConfidenceForRating(this.confidence);
        }
        return this.topicDatabase.getConfidenceForBlock(this.topic.id, blockId, questionHint);
    }
}