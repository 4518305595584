import React from "react";

export function generateUniqueId() : string {
    return (Math.random() * Number.MAX_SAFE_INTEGER).toFixed();
}

export function autoGrowTextArea(event: React.FormEvent<HTMLTextAreaElement>) {
    if (event.target instanceof HTMLTextAreaElement) {
        let element = event.target as HTMLTextAreaElement;
        element.style.height = (element.scrollHeight)+"px";
    }
}

export function shuffle<T>(input: T[]) : T[] {
    return input
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
}

export function oneOfIndex(total: number, exclude: number[] = []) : number {
    const allValues = new Array(total).fill(0).map((_, i) => i);
    const possibleValues = allValues.filter(v => !exclude.some(e => e === v));
    const randomInt = Math.floor(Math.random() * possibleValues.length);
    return possibleValues[randomInt];
}

export function multipleOf<Type>(possibleValues: Type[], numElements: number, include: Type[], exclude: Type[]) : Type[] {
    const selectedElements = include;
    possibleValues = possibleValues.filter(v => !selectedElements.some(e => e === v));
    while (selectedElements.length < numElements && possibleValues.length !== 0) {
        const randomInt = Math.floor(Math.random() * possibleValues.length);
        selectedElements.push(possibleValues[randomInt]);
        possibleValues = possibleValues.filter(v => !selectedElements.some(e => e === v));
        possibleValues = possibleValues.filter(v => !exclude.some(e => e === v));
    }
    return shuffle(selectedElements);
}

const letterSubstitutions = [
    { from: "l", to: "f" },
    { from: "t", to: "s" },
    { from: "tt", to: "t" },
    { from: "pp", to: "p" },
    { from: "sch", to: "sh" },
    { from: "y", to: "i" },
    { from: "s", to: "c" },
    { from: "dd", to: "d" },
    { from: "nn", to: "n" },
    { from: "e", to: "a" },
    { from: "ll", to: "l" },
    { from: "ea", to: "e" },
    { from: "qu", to: "q" },
    { from: "br", to: "b" },
    { from: "pr", to: "p" },
    { from: "sc", to: "s" },
    { from: "k", to: "pk" },
    { from: "m", to: "mm" },
    { from: "n", to: "nn" },
    { from: "a", to: "o" },
    { from: "o", to: "a" },
    { from: "wh", to: "w" },
    { from: "u", to: "o" },
    { from: "o", to: "u" },
    { from: "ie", to: "i" },
    { from: "i", to: "ie" },
    { from: "ä", to: "a" },
    { from: "Ä", to: "A" },
    { from: "ü", to: "u" },
    { from: "Ü", to: "U" },
    { from: "ö", to: "o" },
    { from: "Ö", to: "O" },
    { from: "ä", to: "e" },
    { from: "è", to: "e" },
    { from: "ê", to: "e" },
    { from: "ë", to: "e" },
    { from: "È", to: "E" },
    { from: "Ê", to: "E" },
    { from: "Ë", to: "E" },
    { from: "À", to: "A" },
    { from: "à", to: "a" },
    { from: "Ç", to: "C" },
    { from: "ç", to: "c" },
    { from: "Î", to: "I" },
    { from: "î", to: "i" },
    { from: "Ô", to: "O" },
    { from: "ô", to: "o" },
    { from: "Ù", to: "U" },
    { from: "ù", to: "u" },
    { from: "Û", to: "U" },
    { from: "û", to: "u" },
    { from: "le ", to: "la " },
    { from: "la ", to: "le " },
    { from: "un ", to: "une " },
    { from: "une ", to: "un " },
    { from: "das ", to: "der " },
    { from: "das ", to: "die " },
    { from: "der ", to: "das " },
    { from: "der ", to: "die " },
    { from: "die ", to: "der " },
    { from: "die ", to: "das " },
    { from: "ein ", to: "eine " },
    { from: "eine ", to: "ein " },
];

function substituteLetter(value: string) : string {
    const possibleLetterSubstitutions = letterSubstitutions.filter((substitution) => {
        return value.indexOf(substitution.from) !== -1;
    })
    if (!possibleLetterSubstitutions.length) {
        return value;
    }
    const randomSubstitutionInt = Math.floor(Math.random() * possibleLetterSubstitutions.length);
    return value.replace(possibleLetterSubstitutions[randomSubstitutionInt].from,
                         possibleLetterSubstitutions[randomSubstitutionInt].to);
}

const letterAppends = [
    { letter: "e" },
    { letter: "s" },
    { letter: "en" },
];

function appendAtEnd(value: string) : string {
    const randomAppendInt = Math.floor(Math.random() * letterAppends.length);
    return value + letterAppends[randomAppendInt].letter;
}

function insertIfNotExist(value: string, values: string[]) : string[] {
    if (values.indexOf(value) === -1) {
        values.push(value);
    }
    return values;
}

export function mutationsOf(value: string, numElements: number) : string[] {
    let selectedElements = [value];

    while (selectedElements.length < numElements) {
        if (Math.random() < 0.5 && selectedElements.length < numElements) {
            selectedElements = insertIfNotExist(substituteLetter(value), selectedElements);
        }
        if (Math.random() < 0.5 && selectedElements.length < numElements) {
            selectedElements = insertIfNotExist(appendAtEnd(value), selectedElements);
        }
    }
    return shuffle(selectedElements);
}

export function pickOne(values: string[]): string {
    if (values.length === 0) {
        return "";
    }
    const randomIndex = Math.floor(Math.random() * values.length);
    return values[randomIndex];
}

export const specialCharacters = [
    "Ä", "ä", "À", "à", "Â", "â", "Ç", "ç", "È", "è", "É", "é", "Ê", "ê", "Ë", "ë", "Î", "î", "Ö", "ö", "Ô", "ô", "Ü", "ü", "Ù", "ù", "Û", "û", "ß",
]

export function hashCode(value: string) : string {
   if (value.length === 0) {
        return "";
    }

    let hash = 0;
    for (let i = 0; i < value.length; i++) {
        const char = value.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash;
    }

    return hash.toString();
}