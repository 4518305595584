import React from "react";
import { Block, Vocabulary, VocabularyBlock } from "../Data";
import { TopicDatabase } from "../TopicDatabase";
import {cleanTextInput, MultiValueInput} from "./Common";
import {mutationsOf, pickOne, specialCharacters} from "../Common";
import {Question, QuestionFlags, QuestionMeta} from "./Question";

export interface VocabularyBlockViewProps {
    block: VocabularyBlock;
    showBlockError: (blockId: string, error: string) => void;
    updateBlock: (block: Block) => boolean;
}

export interface VocabularyBlockViewState {
    title: string;
    vocabulary: Vocabulary[];
}

export class VocabularyBlockView extends React.Component<VocabularyBlockViewProps, VocabularyBlockViewState> {
    constructor(props: VocabularyBlockViewProps) {
        super(props);
        this.state = {
            title: this.props.block.title,
            vocabulary: this.props.block.vocabulary,
        }
    }

    onAddVocabulary() {
        let updatedVocabulary = this.state.vocabulary;
        updatedVocabulary.push({
            vocabularies: [""],
            translations: [""],
        });
        this.updateVocabularyContent(updatedVocabulary);
    }

    onDeleteVocabulary(vocabularyIndex: number) {
        const confirmed = window.confirm("Do you want to delete this vocabulary?");
        if (confirmed) {
            let updatedVocabulary = this.state.vocabulary.filter((_, index) => {
                return index !== vocabularyIndex;
            })
            this.updateVocabularyContent(updatedVocabulary);
        }
    }

    onTranslationChanged(vocabularyIndex: number, translations: string[]) {
        const updatedVocabulary = this.state.vocabulary;
        if (vocabularyIndex < 0 || vocabularyIndex >= updatedVocabulary.length) {
            console.log("Try to access vocabulary at index ", vocabularyIndex);
            return;
        }
        updatedVocabulary[vocabularyIndex].translations = translations;
        this.setState((initialState) => {
            return {
                ...initialState,
                vocabulary: updatedVocabulary,
            };
        });
    }

    onVocabularyChanged(vocabularyIndex: number, vocabularies: string[]) {
        const updatedVocabulary = this.state.vocabulary;
        if (vocabularyIndex < 0 || vocabularyIndex >= updatedVocabulary.length) {
            console.log("Try to access vocabulary at index ", vocabularyIndex);
            return;
        }
        updatedVocabulary[vocabularyIndex].vocabularies = vocabularies;
        this.setState((initialState) => {
            return {
                ...initialState,
                vocabulary: updatedVocabulary,
            };
        });
    }

    renderVocabulary() {
        return (
            <table>
                <thead>
                    <tr>
                        <th key="vocabulary">Vocabulary</th>
                        <th key="translation">Translation</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.vocabulary.map((vocabulary, row) => {
                        return (
                            <tr key={row} style={{verticalAlign: "top"}}>
                                <td>
                                    <MultiValueInput
                                        values={vocabulary.vocabularies}
                                        onUpdate={this.onVocabularyChanged.bind(this, row)}
                                        onBlur={this.triggerBlockUpdate.bind(this)}/>
                                </td>
                                <td>
                                    <MultiValueInput
                                        values={vocabulary.translations}
                                        onUpdate={this.onTranslationChanged.bind(this, row)}
                                        onBlur={this.triggerBlockUpdate.bind(this)}/>
                                </td>
                                <td>
                                    <div onClick={this.onDeleteVocabulary.bind(this, row)}
                                         role="button"
                                         className="DeleteCross">x
                                    </div>
                                </td>
                            </tr>)
                    })}
                    <tr>
                        <td>
                            <button onClick={this.onAddVocabulary.bind(this)}
                                    title="Add a new vocabulary row."
                                    className="ActionButton">Add Vocabulary</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    updateVocabularyContent(updatedVocabulary: Vocabulary[]) {
        this.setState((inputState) => {
            return {
                ...inputState,
                vocabulary: updatedVocabulary,
            };
        }, () => {
            this.triggerBlockUpdate();
        });
    }

    updateTitle(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState((inputState) => {
            return {
                ...inputState,
                title: event.target.value,
            };
        });
    }

    triggerBlockUpdate() {
        this.props.updateBlock({
            id: this.props.block.id,
            type: this.props.block.type,
            title: cleanTextInput(this.state.title),
            vocabulary: this.state.vocabulary.map((vocabulary) => {
                return {
                    ...vocabulary,
                    vocabulary: vocabulary.vocabularies.map(v => cleanTextInput(v)),
                    translation: vocabulary.translations.map(v => cleanTextInput(v)),
                }
            }),
        });
    }

    renderSpecialCharacters() {
        return (
            <div className="centerText">{specialCharacters.join(" ")}</div>
        );
    }

    render() {
        return (
            <div className="BlockContent">
                {this.renderSpecialCharacters()}
                <input type="text"
                       value={this.state.title}
                       placeholder="Title"
                       className="fullWidth"
                       onChange={this.updateTitle.bind(this)}
                       onBlur={this.triggerBlockUpdate.bind(this)}/>
                {this.renderVocabulary()}
            </div>
        );
    }
}

export async function createQuestionsForVocabulary(
    block: VocabularyBlock,
    getConfidenceForBlock: (blockId: string,
                            questionHint: string) => Promise<number>): Promise<QuestionMeta[]> {
    const promises = block.vocabulary.map((vocabulary) => {
        const questionHint = vocabulary.vocabularies.length === 0 ? "" : vocabulary.vocabularies[0];
        return getConfidenceForBlock(block.id, questionHint)
            .then((confidence) : QuestionMeta => {
                let question : Question = {
                    type: "text",
                    id: new Date().getTime(),
                    question: TopicDatabase.IsBeginnerConfidence(confidence) ? pickOne(vocabulary.vocabularies) : pickOne(vocabulary.translations),
                    expectedAnswers: TopicDatabase.IsBeginnerConfidence(confidence) ? vocabulary.translations : vocabulary.vocabularies,
                }
                if (TopicDatabase.IsIntermediateConfidence(confidence)) {
                    const voc = pickOne(vocabulary.vocabularies);
                    question = {
                        type: "multipleChoice",
                        id: new Date().getTime(),
                        question: pickOne(vocabulary.translations),
                        possibleAnswers: mutationsOf(voc, 3),
                        expectedAnswer: voc,
                    };
                }
                return {
                    question: question,
                    confidence: confidence,
                    blockId: block.id,
                    questionHint: questionHint,
                    flags: [QuestionFlags.RENDER_SPECIAL_CHARACTERS],
                }
            });
    });
    return Promise.all(promises).then((results) => {
        return results;
    });
}
