import { BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip } from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";
import { MainView, MainViewProps, MainViewState } from "../App";
import { StatsDatabase } from "../StatsDatabase";

Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

interface TopicStatsViewState extends MainViewState {}

interface TopicStatsViewProps extends MainViewProps {
    topicId: string;
}

export class TopicStatsView extends MainView<TopicStatsViewProps, TopicStatsViewState> {
    initialState(): TopicStatsViewState {
        return {};
    }

    handleBack() {
        this.props.navigate(`/`)
    }

    renderCharts() {
        const stats = StatsDatabase.getInstance().getStatsForTopic(this.props.topicId);
        if (stats.length === 0) {
            return <div>No stats available.</div>
        }
        
        const labels = stats.map(s => new Date(s.time).toDateString());
        const chartData = {
            labels,
            datasets: [
                {
                    label: 'correct',
                    data: stats.map(s => s.correctAnswers),
                    backgroundColor: 'rgb(75, 192, 192)',
                },
                {
                    label: 'incorrect',
                    data: stats.map(s => s.incorrectAnswers),
                    backgroundColor: 'rgb(255, 99, 132)',
                },
                ],
        };
        const chartOptions = {
            plugins: {
                title: {
                    display: true,
                    text: 'Answers over time',
                },
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                },
            },
        };

        return <Bar options={chartOptions} data={chartData} />
    }

    renderContentPage(): JSX.Element {
        return (
            <div>
                <h1>
                    <span
                        onClick={this.handleBack.bind(this)}
                        role="button"
                        className="BackArrow">&lt;&lt;
                    </span>
                    Stats
                </h1>
                {this.renderCharts()}
            </div>
        );
    }
}
