import React from "react";
import { autoGrowTextArea } from "../Common";
import { Block, TextBlock } from "../Data";
import { cleanTextInput } from "./Common";
import {ClozePart, QuestionMeta, questionMetaDefaults} from "./Question";

export interface TextBlockViewProps {
    block: TextBlock;
    updateBlock: (block: Block) => boolean;
}

export interface TextBlockViewState {
    text: string;
}

export class TextBlockView extends React.Component<TextBlockViewProps, TextBlockViewState> {
    constructor(props: TextBlockViewProps) {
        super(props);
        this.state = {
            text: this.props.block.text,
        }
    }

    updateText(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({
            text: event.target.value,
        });
    }

    triggerBlockUpdate() {
        this.props.updateBlock({
            id: this.props.block.id,
            type: this.props.block.type,
            text: cleanTextInput(this.state.text),
        });
    }

    render() {
        return (
            <div className="BlockContent">
                <textarea
                    value={this.state.text}
                    onChange={this.updateText.bind(this)}
                    onBlur={this.triggerBlockUpdate.bind(this)}
                    onInput={autoGrowTextArea} />
            </div>
        );
    }
}

export function createQuestionsForText(block: TextBlock,
                                       getConfidenceForBlock: (blockId: string,
                                                               questionHint: string) => Promise<number>): Promise<QuestionMeta[]> {
    return getConfidenceForBlock(block.id, "")
        .then((confidence) => {
            let wordsWithRandom = block.text.split(/[\s,.!?]+/).filter(w => w.length > 3).map(w => ({
                word: w,
                ran: Math.random()
            }));
            if (wordsWithRandom.length <= 2) {
                return [];
            }
            const numWordsToSelect = Math.max(Math.floor(wordsWithRandom.length * confidence / 2), 1);

            const selectedWords = [];
            while (selectedWords.length < numWordsToSelect && wordsWithRandom.length > 0) {
                const selectedWord = wordsWithRandom.reduce((a, b) => (a.ran > b.ran ? a : b));
                selectedWords.push(selectedWord.word);
                const selectedWordIndex = wordsWithRandom.indexOf(selectedWord);
                if (selectedWordIndex === 0) {
                    wordsWithRandom = wordsWithRandom.splice(0, 2);
                } else if (selectedWordIndex > 0) {
                    wordsWithRandom = wordsWithRandom.splice(selectedWordIndex, 3);
                }
            }
        
            const parts: ClozePart[] = [];
            let textPos = 0;
            for (let i = 0; i < selectedWords.length; i++) {
                const selectedWordPos = block.text.indexOf(selectedWords[i], textPos);
                if (selectedWordPos < 0) {
                    continue;
                }
                if (selectedWordPos > textPos) {
                    parts.push({
                        type: "text",
                        text: block.text.substring(textPos, selectedWordPos),
                    });
                }
                textPos = selectedWordPos + selectedWords[i].length;
                parts.push({
                    type: "blank",
                    text: block.text.substring(selectedWordPos, textPos),
                })
            }
            if (block.text.length > textPos) {
                parts.push({
                    type: "text",
                    text: block.text.substring(textPos),
                });
            }
            
            return [{
                ...questionMetaDefaults,
                question: {
                    id: new Date().getTime(),
                    type: "cloze",
                    parts: parts,
                },
                confidence: confidence,
                blockId: block.id,
            }];
    });
}
