import {Topic, TopicMetadata} from "./Data";
import { v4 as uuid } from "uuid"
import { FirebaseApp, initializeApp } from "firebase/app";
import { collection, deleteDoc, doc, DocumentData, DocumentReference, Firestore, getDoc, getDocs, limit, orderBy, Query, query, setDoc, updateDoc, where } from "firebase/firestore";

type FirestoreTopic = {
    readonly id: string;
    userId: string;
    topic: Topic;
};

type FirestoreTopicsMetadata = {
    readonly id: string;
    userId: string;
    topicId: string;
    topicName: string;
};

type FirestoreBlockConfidence = {
    readonly id: string;
    userId: string;
    topicId: string;
    confidence: number;
    timestamp: number;
};

export enum ConfidenceRating {
    Beginner,
    Intermediate,
    Expert,
    SuperStar,
}

export class TopicDatabase {
    private readonly db: Firestore;
    private readonly userId: string;

    public static createFirebase() : FirebaseApp {
        const firebaseConfig = {
            apiKey: "AIzaSyDeKQ20g5eYUFM7EuNGp-T9ixT9zRv4jxA",
            authDomain: "learningapp-358106.firebaseapp.com",
            projectId: "learningapp-358106",
            storageBucket: "learningapp-358106.appspot.com",
            messagingSenderId: "34827748020",
            appId: "1:34827748020:web:cc2c8a8829d4515584cde1"
        };

        return initializeApp(firebaseConfig);
    }

    public constructor(db: Firestore, userId: string) {
        this.userId = userId;
        this.db = db;
    }

    private static getTopicMetadataPath() : string {
        return "topics";
    }

    private static getTopicPath() : string {
        return "topic";
    }
    
    private static getConfidencePath() : string {
        return "confidence";
    }

    private static getFirestoreTopicId(userId: string,
                                       topicId: string) : string {
        return `${userId}@${topicId}`
    }

     private static getFirestoreConfidenceId(userId: string,
                                             topicId: string,
                                             blockId: string,
                                             questionHint: string) : string {
        return `${userId}@${topicId}@${blockId}@${questionHint}`
    }

    private async getTopicDoc(ref: DocumentReference<DocumentData>): Promise<Topic | undefined> {
        return getDoc(ref)
            .catch((error) => {
                console.log(error);
                return undefined;
            })
            .then((snapshot) => {
                if (snapshot === undefined || !snapshot.exists()) {
                    return undefined;
                }
                const firestoreTopic = this.parseFirefoxTopic(snapshot.data());
                return firestoreTopic.topic;
            });
    }

    private parseFirefoxTopic(data: DocumentData) : FirestoreTopic {
        data.topic.blocks = data.topic.blocks.map((block: { type: any; words: any[]; word: any; imageMarkers: any[]; columns: any[]; value: any; vocabulary: any[]; }) => {
            switch (block.type) {
                case "definition":
                    if (!("words" in block) && ("word" in block)) {
                        block.words = [block.word];
                    }
                    return block;
                case "image":
                    block.imageMarkers = block.imageMarkers.map((imageMarker) => {
                        if (!("labels" in imageMarker) && ("label" in imageMarker)) {
                            imageMarker.labels = [imageMarker.label];
                        }
                        return imageMarker;
                    })
                    return block;
                case "table":
                    block.columns = block.columns.map((column) => {
                        column.cells = column.cells.map((cell: { type: string; values: any[]; value: any; }) => {
                            if (cell.type === "text" && !("values" in cell) && ("value" in cell)) {
                                cell.values = [cell.value];
                            }
                            return cell;
                        })
                        return column;
                    })
                    return block;
                case "vocabulary":
                    block.vocabulary = block.vocabulary.map((vocabulary) => {
                        if (!("vocabularies" in vocabulary) && ("vocabulary" in vocabulary)) {
                            vocabulary.vocabularies = [vocabulary.vocabulary];
                        }
                        if (!("translations" in vocabulary) && ("translation" in vocabulary)) {
                            vocabulary.translations = [vocabulary.translation];
                        }
                        return vocabulary;
                    })
                    return block;
                default:
                    return block;
            }
        })
        return data as FirestoreTopic;
    }

    private async getTopicMetadataDocs(query: Query<DocumentData>): Promise<TopicMetadata[]>{
        return getDocs(query)
            .catch((error) => {
                console.log(error);
                return undefined;
            })
            .then((snapshots) => {
                if (snapshots === undefined) {
                    return [];
                }
                return snapshots.docs.map((doc) => {
                    const firestoreTopicMetadata = doc.data() as FirestoreTopicsMetadata;
                    return {
                        id: firestoreTopicMetadata.topicId,
                        name: firestoreTopicMetadata.topicName,
                    }
                });
            });
    }

    public async recentTopics() : Promise<TopicMetadata[]> {
        return this.getTopicMetadataDocs(query(
            collection(this.db, TopicDatabase.getTopicMetadataPath()),
            where("userId", "==", this.userId),
            orderBy("timestamp", "desc"),
            limit(50)));
    }

    public async getTopicById(topicId: string) : Promise<Topic | undefined> {
        return this.getTopicDoc(doc(
            this.db,
            TopicDatabase.getTopicPath(),
            TopicDatabase.getFirestoreTopicId(this.userId, topicId)));
    }

    public updateTopic(topic: Topic) : Promise<void> {
        return updateDoc(doc(this.db,
                             TopicDatabase.getTopicPath(),
                             TopicDatabase.getFirestoreTopicId(this.userId, topic.id)),
                         {topic: topic});
    }

    public async createTopic(topicName: string) : Promise<string | undefined> {
        const topicId = uuid();
        // Write topic.
        const firestoreTopic = {
            id: TopicDatabase.getFirestoreTopicId(this.userId, topicId),
            userId: this.userId,
            topic: {
                id: topicId,
                name: topicName,
                blocks: [],
            },
        }
        const createTopic = setDoc(doc(
            this.db, TopicDatabase.getTopicPath(), firestoreTopic.id),
            firestoreTopic)
            .catch((error) => {
                console.log(error);
                return undefined;
            });
        // Write topic metadata.
        const firestoreTopicMetadata = {
            id: TopicDatabase.getFirestoreTopicId(this.userId, topicId),
            userId: this.userId,
            topicId: topicId,
            topicName: topicName,
            timestamp: new Date().getTime(),
        }
        const createTopicMetadata = setDoc(doc(
            this.db, TopicDatabase.getTopicMetadataPath(), firestoreTopicMetadata.id),
            firestoreTopicMetadata)
            .catch((error) => {
                console.log(error);
                return undefined;
            });

        return Promise.all([createTopic, createTopicMetadata])
            .then(() => {
                return topicId;
            });
    }

    public async deleteTopic(topicId: string) : Promise<void> {
        const deleteTopic = deleteDoc(
            doc(this.db,
                TopicDatabase.getTopicPath(),
                TopicDatabase.getFirestoreTopicId(this.userId, topicId)));
        const deleteTopicMetadata = deleteDoc(
            doc(this.db,
                TopicDatabase.getTopicMetadataPath(),
                TopicDatabase.getFirestoreTopicId(this.userId, topicId)));
        return Promise.all([deleteTopic, deleteTopicMetadata])
            .catch((error) => {
                console.log(error);
                return;
            })
            .then(() => {
                return;
            });
    }

    public async getConfidenceForBlock(topicId: string,
                                       blockId: string,
                                       questionHint: string) : Promise<number> {
        return getDoc(doc(
                this.db,
                TopicDatabase.getConfidencePath(),
                TopicDatabase.getFirestoreConfidenceId(this.userId, topicId, blockId, questionHint))
            )
            .catch((error) => {
                console.log(error);
                return undefined;
            })
            .then((snapshot) => {
                if (snapshot === undefined || !snapshot.exists()) {
                    console.log("Doc does not exist");
                    return TopicDatabase.getInitalConfidence();
                }
                const firestoreConfidence = snapshot.data() as FirestoreBlockConfidence;
                return firestoreConfidence.confidence;
            });
    }

    public updateConfidenceForBlock(topicId: string,
                                    blockId: string,
                                    questionHint: string,
                                    confidence: number,
                                    correct: boolean) {
        let newConfidence: number;
        if (correct) {
            newConfidence = Math.min(0.9, confidence * 1.5);
        } else {
            newConfidence = Math.max(0.1, confidence / 1.5)
        }
        const firebaseConfidence = {
            id: TopicDatabase.getFirestoreConfidenceId(this.userId, topicId, blockId, questionHint),
            userId: this.userId,
            topicId: topicId,
            confidence: newConfidence,
        };
        setDoc(
            doc(this.db,
                TopicDatabase.getConfidencePath(),
                firebaseConfidence.id),
            firebaseConfidence)
            .catch((error) => {console.log(error)});
        return newConfidence;
    }

    private static getInitalConfidence() : number {
        return 0.3;
    }

    public static IsBeginnerConfidence(confidence: number) : boolean {
        return confidence < 0.4;
    }

    public static IsIntermediateConfidence(confidence: number) : boolean {
        return confidence >= 0.4 && confidence < 0.6;
    }

    public static getConfidenceForRating(confidenceRaging: ConfidenceRating) {
        switch (confidenceRaging) {
            case ConfidenceRating.Beginner:
                return 0.3;
            case ConfidenceRating.Intermediate:
                return 0.5;
            case ConfidenceRating.Expert:
                return 0.7;
            case ConfidenceRating.SuperStar:
                return 0.9;
        }
    }

    private getConfidenceRating(confidenceValues: number[]) : ConfidenceRating {
        if (confidenceValues.length === 0) {
            return ConfidenceRating.Beginner;
        }
        const confidence = confidenceValues.reduce((a, b) => a + b) / confidenceValues.length;
        if (TopicDatabase.IsBeginnerConfidence(confidence)) {
            return ConfidenceRating.Beginner;
        } else if (TopicDatabase.IsIntermediateConfidence(confidence)) {
            return ConfidenceRating.Intermediate;
        } else if (confidence < 0.8) {
            return ConfidenceRating.Expert;
        } else {
            return ConfidenceRating.SuperStar;
        }
    }

    public async getConfidenceRatingForTopic(topicId: string) : Promise<ConfidenceRating> {
        return getDocs(query(collection(this.db, TopicDatabase.getConfidencePath()),
                             where("userId", "==", this.userId),
                             where("topicId", "==", topicId)))
            .catch((error) => {
                console.log(error);
                return undefined;
            })
            .then((snapshots) => {
                let confidences: number[] = [];
                if (snapshots !== undefined) {
                    confidences = snapshots.docs.map((doc) => {
                        const confidenceRecord = doc.data() as FirestoreBlockConfidence;
                        return confidenceRecord.confidence;
                    });
                }
                return this.getConfidenceRating(confidences);
            });
    }
}
