import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import './App.css';
import { AppUserState } from './Data';

export interface MainViewProps {
    navigate: NavigateFunction;
    appState: AppUserState;
    logout: (navigate: NavigateFunction) => void;
}

export interface MainViewState {}

export abstract class MainView<Props extends MainViewProps, State extends MainViewState> extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = this.initialState();
    }

    abstract initialState() : State;

    abstract renderContentPage() : JSX.Element;

    showError(error: string) {
        console.log(error);
    }

    logout() {
        this.props.logout(this.props.navigate);
    }

    render() {
        return (
            <div className="App">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <div className="rightFlow">
                                    <button
                                        onClick={this.logout.bind(this)}
                                        title="Logout the user and switch to another one."
                                        className="ActionButton">Switch user</button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>{this.renderContentPage()}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
