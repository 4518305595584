import React from "react";
import {
    Block,
    createDefaultCell,
    ImageCell,
    TableBlock,
    TableCell,
    TableCellType,
    TableColumn,
    TextCell
} from "../Data";
import { TopicDatabase } from "../TopicDatabase";
import {cleanTextInput, drawImage, MultiValueInput} from "./Common";
import {
    MultiInputQuestionTypes,
    QuestionMeta,
    questionMetaDefaults,
    renderValue,
    ViewRenderType
} from "./Question";
import {multipleOf, mutationsOf, oneOfIndex, pickOne, shuffle} from "../Common";

export interface TableBlockViewProps {
    block: TableBlock;
    showBlockError: (blockId: string, error: string) => void;
    updateBlock: (block: Block) => boolean;
}

export interface TableBlockViewState {
    title: string;
    newColumnName: string;
    newColumnType: TableCellType;
    columns: TableColumn[];
}

export class TableBlockView extends React.Component<TableBlockViewProps, TableBlockViewState> {
    constructor(props: TableBlockViewProps) {
        super(props);
        this.state = {
            title: this.props.block.title,
            newColumnName: "",
            newColumnType: "text" as TableCellType,
            columns: this.props.block.columns,
        }
    }

    getColumn(columnName: string): TableColumn | undefined {
        return this.state.columns.find((c: TableColumn) => {
            return c.name === columnName;
        });
    }

    createDefaultColumn(columnName: string, columnType: TableCellType): TableColumn {
        const numCells = !this.state.columns.length ? 1 : this.state.columns[0].cells.length;
        return {
            name: columnName,
            type: columnType,
            cells: Array.from({length: numCells}, (_) => createDefaultCell(columnType)),
        };
    }

    onAddColumn(event: React.SyntheticEvent) {
        event.preventDefault();
        if (!this.state.newColumnName) {
            this.props.showBlockError(this.props.block.id, "Please specify a column name.");
            return;
        }

        let newColumn: TableColumn = this.createDefaultColumn(this.state.newColumnName, this.state.newColumnType);
        let column = this.getColumn(newColumn.name);
        if (column) {
            this.props.showBlockError(this.props.block.id, "Column with the same name already exists.");
            return;
        }

        let updatedColumns = this.state.columns;
        updatedColumns.push(newColumn);
        this.updateColumnsState(updatedColumns);
        this.setState((inputState) => {
            return {
                ...inputState,
                newColumnName: "",
            };
        });
    }

    deleteColumn(columnName: string) {
        const confirmed = window.confirm(`Do you want to delete column ${columnName}?`);
        if (confirmed) {
            let updatedColumns = this.state.columns.filter((column) => {
                return column.name !== columnName;
            });
            this.updateColumnsState(updatedColumns);
        }
    }

    deleteRow(rowIndex: number) {
        if (this.state.columns && this.state.columns[0].cells.length <= 1) {
            this.props.showBlockError(this.props.block.id, "Will not delete single row. Delete the whole block instead.");
            return;
        }
        const confirmed = window.confirm("Do you want to delete this row?");
        if (confirmed) {
            let updatedColumns = this.state.columns.map((column) => {
                let updatedCells: Array<TableCell> = [];
                column.cells.forEach((cell, index) => {
                    if (index !== rowIndex) {
                        updatedCells.push(cell);
                    }
                });
                column.cells = updatedCells;
                return column;
            });
            this.updateColumnsState(updatedColumns);
        }
    }

    addRow() {
        let updatedColumns = this.state.columns.map((column: TableColumn) => {
            column.cells.push(createDefaultCell(column.type))
            return column;
        })
        this.updateColumnsState(updatedColumns);
    }

    renderCell(cell: TableCell) {
        switch (cell.type) {
            case "text":
                return (<TextCellView cell={cell}
                                      updateCell={this.updateCell.bind(this)}/>);
            case "image":
                return (<ImageCellView cell={cell}
                                      updateCell={this.updateCell.bind(this)}/>);
        }
    }

    renderTable() {
        if (this.state.columns && this.state.columns.length) {
            return (<table>
                <thead>
                <tr>
                    {this.state.columns.map((column) => (
                        <th key={column.name}>
                            {column.name}
                            <div onClick={this.deleteColumn.bind(this, column.name)}
                                 role="button"
                                 className="DeleteCross">x
                            </div>
                        </th>))}
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {Array.from(Array(!this.state.columns.length ? 0 : this.state.columns[0].cells.length), (e, row) => {
                    return (
                        <tr key={row}>
                            {this.state.columns.map((column: TableColumn) => {
                                return (
                                    <td key={column.cells[row].id}>
                                        {this.renderCell(column.cells[row])}
                                    </td>)
                            })}
                            <td>
                                <div onClick={this.deleteRow.bind(this, row)}
                                     role="button"
                                     className="DeleteCross">x
                                </div>
                            </td>
                        </tr>)
                })}
                <tr>
                    <td>
                    <button onClick={this.addRow.bind(this)}
                            title="Add a row to the table."
                            className="ActionButton">Add Row</button>
                    </td>
                </tr>
                </tbody>
            </table>);
        }
    }

    updateColumnsState(updatedColumns: TableColumn[]) {
        this.setState((inputState) => {
            return {
                ...inputState,
                columns: updatedColumns,
            };
        }, () => {
            this.triggerBlockUpdate();
        });
    }

    updateCell(updatedCell: TableCell) {
        let updatedColumns = this.state.columns;
        updatedColumns.forEach((column: TableColumn, columnIndex: number) => {
            column.cells.forEach((cell: TableCell, cellIndex: number) => {
                if (cell.id === updatedCell.id) {
                    updatedColumns[columnIndex].cells[cellIndex] = updatedCell;
                }
            });
        });
        this.updateColumnsState(updatedColumns);
    }

    updateTitle(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState((inputState) => {
            return {
                ...inputState,
                title: event.target.value,
            };
        });
    }

    updateNewColumnName(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState((inputState) => {
            return {
                ...inputState,
                newColumnName: event.target.value,
            };
        });
    }

    updateNewColumnType(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState((inputState) => {
            return {
                ...inputState,
                newColumnType: event.target.value as TableCellType,
            };
        });
    }

    triggerBlockUpdate() {
        function cleanCells(column: TableColumn) {
            return column.cells.map((cell) => {
                if ("values" in cell) {
                    return {
                        ...cell,
                        values: cell.values.map(l => cleanTextInput(l))
                    };
                } else {
                    return cell;
                }
            });
        }

        this.props.updateBlock({
            id: this.props.block.id,
            type: this.props.block.type,
            title: cleanTextInput(this.state.title),
            columns: this.state.columns.map((column) => {
                return {
                    ...column,
                    name: cleanTextInput(column.name),
                    cells: cleanCells(column),
                }
            }),
        });
    }

    render() {
        return (
            <div className="BlockContent">
                <input type="text"
                       value={this.state.title}
                       placeholder="Title"
                       className="fullWidth"
                       onChange={this.updateTitle.bind(this)}
                       onBlur={this.triggerBlockUpdate.bind(this)}/>
                <div>
                    <form onSubmit={this.onAddColumn.bind(this)}>
                        <input type="text"
                               placeholder="Column name"
                               value={this.state.newColumnName}
                               onChange={this.updateNewColumnName.bind(this)}/>
                        <select value={this.state.newColumnType}
                                onChange={this.updateNewColumnType.bind(this)}>
                            <option value="text">Text</option>
                            <option value="image">Image</option>
                        </select>
                        <button className="ActionButton"
                                title="Add a column to the table">Add Column</button>
                    </form>
                </div>
                {this.renderTable()}
            </div>
        );
    }
}

interface TextCellViewProps {
    cell: TextCell;
    updateCell: (cell: TableCell) => void;
}

interface TextCellViewState {
    cell: TextCell;
}

class TextCellView extends React.Component<TextCellViewProps, TextCellViewState> {
    constructor(props: TextCellViewProps) {
        super(props);
        this.state = {
            cell: this.props.cell,
        }
    }

    updateCellValues(values: string[]) {
        this.setState((inputState) => {
            let updatedCell = inputState.cell;
            updatedCell.values = values;
            return {
                cell: updatedCell,
            };
        });
    }

    triggerCellUpdate() {
        this.props.updateCell(this.state.cell);
    }

    render() {
        return (
            <div className="TableCell">
                <MultiValueInput
                    values={this.state.cell.values}
                    onUpdate={this.updateCellValues.bind(this)}
                    onBlur={this.triggerCellUpdate.bind(this)}/>
            </div>
        );
    }
}

interface ImageCellViewProps {
    cell: ImageCell;
    updateCell: (cell: TableCell) => void;
}

interface ImageCellViewState {
    cell: ImageCell;
}
class ImageCellView extends React.Component<ImageCellViewProps, ImageCellViewState> {
    constructor(props: ImageCellViewProps) {
        super(props);
        this.state = {
            cell: this.props.cell,
        }
    }


    loadFile(event: ProgressEvent<FileReader>) {
        this.setState((inputState) => {
            let updatedCell = inputState.cell;
            updatedCell.image = {
                bytes: event.target!.result as string,
                reference: "",
            };
            return {
                cell: updatedCell,
            };
        });
    }

    onChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!event.target.files) {
            return;
        }
        let fileReader = new FileReader();
        fileReader.onloadend = this.loadFile.bind(this);
        fileReader.readAsDataURL(event.target.files[0]);
    }

    onLeave() {
        this.props.updateCell(this.state.cell);
    }

    render() {
        return (
            <div className="TableCell">
                {drawImage(this.state.cell.image)}
                <input type="file"
                       accept="image/*"
                       onChange={this.onChange.bind(this)}
                       onBlur={this.onLeave.bind(this)} />
            </div>
        );
    }
}

export async function createQuestionsForTable(
    block: TableBlock,
    getConfidenceForBlock: (blockId: string,
                            questionHint: string) => Promise<number>): Promise<QuestionMeta[]> {
    if (block.columns.length < 2) {
        return [];
    }

    const numCells = block.columns[0].cells.length;

    function getCellValue(cell: TableCell) {
        switch (cell.type) {
            case "text":
                return pickOne(cell.values);
            case "image":
                return cell.image;
        }
    }

    function createQuestionForCell(column: TableColumn, cellIndex: number, id: number) : MultiInputQuestionTypes {
        switch (column.type) {
            case "text":
                const textCell = column.cells[cellIndex] as TextCell;
                return {
                     type: "text",
                     id: id,
                     question: column.name,
                     expectedAnswers: textCell.values,
                 };
            case "image":
                const imageCell = column.cells[cellIndex] as ImageCell;
                return {
                    type: "multipleChoice",
                    id: id,
                    question: column.name,
                    possibleAnswers: multipleOf(column.cells.map(cell => {
                        const imageCell = cell as ImageCell;
                        return imageCell.image;
                    }), 3, [imageCell.image], [imageCell.image]),
                    expectedAnswer: imageCell.image,
                };
        }
    }

    function getQuestionTitle(column: TableColumn, otherColumn: number, cell: number) : JSX.Element {
        return (
            <div>{column.name} for {block.columns[otherColumn].name} = {renderValue(getCellValue(block.columns[otherColumn].cells[cell]))}</div>
        );
    }

    function getTitleForCell(column: TableColumn, cell: number) : JSX.Element {
        return (
            <div>{column.name}: {renderValue(getCellValue(column.cells[cell]))}</div>
        );
    }

    const promises = new Array(numCells).fill(0).map((_, cell: number) => {
        return getConfidenceForBlock(block.id, cell.toString())
            .then((confidence) => {
                if (TopicDatabase.IsBeginnerConfidence(confidence) ||
                    TopicDatabase.IsIntermediateConfidence(confidence)) {
                    return block.columns.map((column: TableColumn, columnIndex: number) => {
                        const otherColumn = oneOfIndex(block.columns.length, [columnIndex]);
                        let possibleAnswers : ViewRenderType[] = [];
                        if (column.cells[cell].type === "text") {
                            const textCell = column.cells[cell] as TextCell;
                            if (TopicDatabase.IsIntermediateConfidence(confidence) && column.cells[cell].type === "text") {
                                possibleAnswers = mutationsOf(pickOne(textCell.values), 3);
                            } else {
                                possibleAnswers = multipleOf(column.cells.flatMap(cell => {
                                    const textCell = cell as TextCell;
                                    return textCell.values
                                }), 3, [pickOne(textCell.values)], textCell.values);
                            }
                        } else if (column.cells[cell].type === "image") {
                            const imageCell = column.cells[cell] as ImageCell;
                            possibleAnswers = multipleOf(column.cells.map(cell => {
                                const imageCell = cell as ImageCell;
                                return imageCell.image
                            }), 3, [imageCell.image], [imageCell.image]);
                        }
                        const question: QuestionMeta = {
                            ...questionMetaDefaults,
                            question: {
                                type: "multipleChoice",
                                id: new Date().getTime(),
                                question: getQuestionTitle(column, otherColumn, cell),
                                possibleAnswers: possibleAnswers,
                                expectedAnswer: getCellValue(column.cells[cell])
                            },
                            confidence: confidence,
                            blockId: block.id,
                            questionHint: cell.toString(),
                        }
                        return question;
                    });
                } else {
                    const questionColumns = shuffle(block.columns);
                    const titleColumn = questionColumns[0];
                    questionColumns.splice(0, 1);
                    const id = new Date().getTime();
                    const question: QuestionMeta = {
                        ...questionMetaDefaults,
                        question: {
                            type: "multiInput",
                            id: new Date().getTime(),
                            title: getTitleForCell(titleColumn, cell),
                            questions: questionColumns.map((column, columnId) => {
                                return createQuestionForCell(column, cell, id + columnId);
                            }),
                        },
                        confidence: confidence,
                        blockId: block.id,
                        questionHint: cell.toString(),
                    };
                    return [question];
                }
            });
    });
    return Promise.all(promises).then((results) => {
        return results.flat();
    })
}
