import {
    Block,
    verifyBlock,
    BlockType,
    createDefaultBlock,
} from '../Data'
import React from "react";
import { DefinitionBlockView } from './Definition';
import { TextBlockView } from './Text';
import { TableBlockView } from './Table';
import { ImageBlockView } from './Image';
import { MainView, MainViewProps, MainViewState } from '../App';
import {VocabularyBlockView} from "./Vocabulary";

interface TopicEditorViewProps extends MainViewProps {
    topicId: string;
}

interface TopicEditorViewState extends MainViewState {
    topicName: string | undefined;
    blocks: Block[];
}

export class TopicEditorView extends MainView<TopicEditorViewProps, TopicEditorViewState> {
    componentDidMount() {
        this.props.appState.topicDatabase.getTopicById(this.props.topicId)
            .then(topic => {
                if (!topic) {
                    this.showError("Failed to find Topic");
                } else {
                    this.setState((inputState) => {
                        return {
                            ...inputState,
                            topicName: topic.name,
                            blocks: topic.blocks,
                        };
                    });
                }
            });
    }

    initialState(): TopicEditorViewState {
        return {
            topicName: undefined,
            blocks: [],
        }
    }

    renderBlock(block: Block) {
        switch (block.type) {
            case "text":
                return (<TextBlockView block={block}
                                       updateBlock={this.updateBlock.bind(this)} />);
            case "definition":
                return (<DefinitionBlockView block={block}
                                             updateBlock={this.updateBlock.bind(this)}  />);
            case "table":
                return (<TableBlockView block={block}
                                        showBlockError={this.showBlockError.bind(this)}
                                        updateBlock={this.updateBlock.bind(this)} />);
            case "image":
                return (<ImageBlockView block={block}
                                        showBlockError={this.showBlockError.bind(this)}
                                        updateBlock={this.updateBlock.bind(this)} />);
            case "vocabulary":
                return (<VocabularyBlockView block={block}
                                             showBlockError={this.showBlockError.bind(this)}
                                             updateBlock={this.updateBlock.bind(this)} />);
        }
    }

    showBlockError(blockId: string, message: string) {
        console.log("Error for Block[" + blockId + "] --- " + message);
    }

    updateBlocksState(updatedBlocks: Block[]) {
        if (!this.state.topicName) {
            console.log("TopicName is not specified.");
            return;
        }
        this.props.appState.topicDatabase.updateTopic({
            id: this.props.topicId,
            name: this.state.topicName,
            blocks: updatedBlocks,
        });
        this.setState((inputState) => {
            return {
                topicName: inputState.topicName,
                blocks: updatedBlocks,
            };
        });
    }
    
    addBlock(blockType: BlockType) {
        this.updateBlocksState(
            this.state.blocks.concat(createDefaultBlock(blockType)));
    }

    deleteBlock(block: Block) {
        this.updateBlocksState(this.state.blocks.filter((b: Block) => b.id !== block.id))
    }

    updateBlock(updatedBlock: Block): boolean {
        const valid = verifyBlock(updatedBlock);
        if (valid !== "") {
            this.showBlockError(updatedBlock.id, valid);
            return false;
        }
        this.updateBlocksState(this.state.blocks.map((b: Block) => {
            if (b.id !== updatedBlock.id) {
                return b;
            } else {
                return updatedBlock;
            }
        }));
        return true;
    }

    handleBack() {
        this.props.navigate(`/`)
    }

    renderContentPage(): JSX.Element {
        if (!this.state.topicName) {
            return (<div>Loading...</div>)
        } else {
            return (
                <div>
                    <h1>
                        <span
                            onClick={this.handleBack.bind(this)}
                            role="button"
                            className="BackArrow">&lt;&lt;
                        </span>
                        {this.state.topicName}
                    </h1>
                    {this.state.blocks.map(block => {
                        return (
                            <div key={block.id} className="Block">
                                <DeleteBlockView block={block}
                                                deleteBlock={this.deleteBlock.bind(this)}/>
                                {this.renderBlock(block)}
                            </div>
                        );
                    })}
                    <AddBlockView addBlock={this.addBlock.bind(this)}/>
                </div>
            );
        }
    }
}

interface DeleteBlockViewProps {
    block: Block;
    deleteBlock: (block: Block) => void;
}

class DeleteBlockView extends React.Component<DeleteBlockViewProps, {}> {
    handleDeleteBlock() {
        const confirmed = window.confirm("Do you want to delete this block?");
        if (confirmed) {
            this.props.deleteBlock(this.props.block);
        }
    }

    render() {
        return (
            <div
                onClick={this.handleDeleteBlock.bind(this)}
                role="button"
                className="DeleteCross"
            >x</div>
        );
    }
}

interface AddBlockViewProps {
    addBlock: (blockType: BlockType) => void;
}

class AddBlockView extends React.Component<AddBlockViewProps, {}> {
    render() {
        return (
            <div className="ActionBar">
                <button
                    onClick={this.props.addBlock.bind(this, "text")}
                    title="Create a text block"
                    className="ActionButton">text</button>
                <button
                    onClick={this.props.addBlock.bind(this, "definition")}
                    title="Create a definition block"
                    className="ActionButton">definition</button>
                <button
                    onClick={this.props.addBlock.bind(this, "table")}
                    title="Create a table block"
                    className="ActionButton">table</button>
                <button
                    onClick={this.props.addBlock.bind(this, "image")}
                    title="Create an image block"
                    className="ActionButton">image</button>
                <button
                    onClick={this.props.addBlock.bind(this, "vocabulary")}
                    title="Create a vocabulary block"
                    className="ActionButton">vocabulary</button>
            </div>
        );
    }
}
