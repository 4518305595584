import {cleanTextInput, drawImage} from "./Common";
import {Point, RawImageData} from "../Data";

export enum QuestionFlags {
    RENDER_SPECIAL_CHARACTERS = 1,
}

export const questionMetaDefaults = {
    flags: [],
    questionHint: "",
}

export type QuestionMeta = {
    question: Question;
    confidence: number;
    blockId: string;
    questionHint: string;
    flags: QuestionFlags[];
}

export type ViewRenderType = string | RawImageData;
export type Question = TextQuestion | MultipleChoiceQuestion | MultiInputQuestion | ClozeQuestion | ImageQuestion;

export type TextQuestion = {
    readonly type: "text";
    id: number;
    question: string | JSX.Element;
    expectedAnswers: string[];
};

export type MultipleChoiceQuestion = {
    readonly type: "multipleChoice";
    id: number;
    question: string | JSX.Element;
    possibleAnswers: ViewRenderType[];
    expectedAnswer: ViewRenderType;
};

export type MultiInputQuestionTypes = MultipleChoiceQuestion | TextQuestion;
export type MultiInputQuestion = {
    readonly type: "multiInput";
    id: number;
    title: JSX.Element;
    questions: MultiInputQuestionTypes[];
};

export type ClozeQuestion = {
    readonly type: "cloze";
    id: number;
    parts: ClozePart[];
};

export type ClozePart = {
    readonly type: "text" | "blank";
    text: string;
}

export type ImageQuestion = {
    readonly type: "image",
    id: number;
    title: string;
    image: RawImageData;
    position: Point;
    question: MultipleChoiceQuestion | TextQuestion;
}

export function renderValue(value: ViewRenderType) {
    if (typeof value === "string") {
        return value;
    } else {
        return drawImage(value as RawImageData);
    }
}

export function cleanViewRenderType(value: ViewRenderType) {
    if (typeof value === "string") {
        return cleanTextInput(value);
    } else {
        return value;
    }
}

export function getExpectedAnswers(question : MultipleChoiceQuestion | TextQuestion) {
    if (question.type === "multipleChoice") {
        return [question.expectedAnswer];
    } else {
        return question.expectedAnswers;
    }
}