import { StatsEntry } from "./Data";

export class StatsDatabase {
    private static instance: StatsDatabase;

    public static getInstance(): StatsDatabase {
        if (!StatsDatabase.instance) {
            StatsDatabase.instance = new StatsDatabase();
        }

        return StatsDatabase.instance;
    }

    private roundToDay(date: Date) : number {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
    }

    private static getStatsKey(topicId: string, time: number) : string {
        return `${StatsDatabase.getStatsPrefixForTopic(topicId)}_${time}`;
    }

    private static getStatsPrefixForTopic(topicId: string) : string {
        return `stats_topic_${topicId}`;
    }

    public updateStatsForTopic(topicId: string,
                               correctAnswer: boolean) {
        const time = this.roundToDay(new Date());
        const statsKey = StatsDatabase.getStatsKey(topicId, time);
        const data = localStorage.getItem(statsKey);
        let stats;
        if (!data) {
            stats = {
                time: time,
                correctAnswers: 0,
                incorrectAnswers: 0
            };
        } else {
            stats = JSON.parse(data);
        }
        if (correctAnswer) {
            stats.correctAnswers++;
        } else {
            stats.incorrectAnswers++;
        }
        localStorage.setItem(statsKey, JSON.stringify(stats));
    }

    public getStatsForTopic(topicId: string): StatsEntry[] {
        return Object.entries(localStorage)
            .filter(v => v[0].startsWith(StatsDatabase.getStatsPrefixForTopic(topicId)))
            .map(v => JSON.parse(v[1]))
            .sort((a, b) => { return a.time - b.time; })
            .slice(-30);
    }
}