import React from "react";
import { Block, ImageBlock, RawImageData, ImageMarker } from "../Data";
import {multipleOf, mutationsOf, pickOne} from "../Common";
import { TopicDatabase } from "../TopicDatabase";
import {cleanTextInput, MultiValueInput} from "./Common";
import {MultipleChoiceQuestion, QuestionMeta, questionMetaDefaults, TextQuestion} from "./Question";

export interface ImageBlockViewProps {
    block: ImageBlock;
    showBlockError: (blockId: string, error: string) => void;
    updateBlock: (block: Block) => boolean;
}

export interface ImageBlockViewState {
    title: string;
    image: RawImageData;
    imageHeight: number;
    imageMarkers: ImageMarker[];
}

export class ImageBlockView extends React.Component<ImageBlockViewProps, ImageBlockViewState> {
    constructor(props: ImageBlockViewProps) {
        super(props);
        this.state = {
            title: this.props.block.title,
            image: this.props.block.image,
            imageHeight: 0,
            imageMarkers: this.props.block.imageMarkers,
        }
    }

    updateTitle(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState((initialState) => {
            return {
                ...initialState,
                title: event.target.value,
            };
        });
    }

    loadFile(event: ProgressEvent<FileReader>) {
        if (!event.target) {
            this.props.showBlockError(this.props.block.id, "Failed to upload image.");
            return;
        }
        this.setState((initialState) => {
                const image: RawImageData = {
                    bytes: event.target!.result as string,
                    reference: "",
                }
                return {
                    ...initialState,
                    image: image,
                };
            });
    }

    updateImage(event: React.ChangeEvent<HTMLInputElement>) {
        if (!event.target.files) {
            this.props.showBlockError(this.props.block.id, "Failed to find file.");
            return;
        }
        let fileReader = new FileReader();
        fileReader.onloadend = this.loadFile.bind(this);
        fileReader.onerror = this.props.showBlockError.bind(this, this.props.block.id, "Loading image failed.")
        fileReader.readAsDataURL(event.target.files[0]);
    }

    triggerBlockUpdate() {
        this.props.updateBlock({
            id: this.props.block.id,
            type: this.props.block.type,
            title: cleanTextInput(this.state.title),
            image: this.state.image,
            imageMarkers: this.state.imageMarkers.map((imageMarker) => {
                    return {
                        ...imageMarker,
                        labels: imageMarker.labels.map(l => cleanTextInput(l)),
                    };
                }),
        });
    }

    addImageMarker(imageMarker: ImageMarker) {
        let updatedImageMarkers = this.state.imageMarkers;
        updatedImageMarkers.push(imageMarker);
        this.setState((initialState) => {
            return {
                ...initialState,
                imageMarkers: updatedImageMarkers,
            };
        });
    }

    createImageMarker(event: React.MouseEvent<HTMLCanvasElement>) {
        const canvas = event.currentTarget;
        this.addImageMarker({
            labels: [""],
            position: {
                x: event.clientX - canvas.getBoundingClientRect().left - getImageWidthOffset(),
                y: event.clientY - canvas.getBoundingClientRect().top - getImageHeightOffset(this.state.imageHeight, this.state.imageMarkers),
            },
        });
    }

    updateImageSize(width: number, height: number) {
        if (this.state.imageHeight !== height) {
            this.setState((initialState) => {
                return {
                    ...initialState,
                    imageHeight: height,
                };
            });  
        }
    }

    drawImageWithMarkers(canvas: HTMLCanvasElement) {
        drawImageWithMarkers(
            canvas,
            this.state.image,
            this.state.imageMarkers,
            this.props.showBlockError.bind(this, this.props.block.id),
            this.updateImageSize.bind(this));
    }

    updateImageMarkerLabel(imageMarkerIndex: number, labels: string[]) {
        const updatedImageMarkers = this.state.imageMarkers;
        updatedImageMarkers[imageMarkerIndex].labels = labels;
        this.setState((initialState) => {
            return {
                title: initialState.title,
                image: initialState.image,
                imageMarkers: updatedImageMarkers,
            };
        });
    }

    deleteMarkerAtIndex(imageMarkerIndex: number) {
        if (imageMarkerIndex < 0 || imageMarkerIndex >= this.state.imageMarkers.length) {
            console.log("Invalid imageMarkerIndex: ", imageMarkerIndex);
        }
        let updatedImageMarkers = this.state.imageMarkers.filter((_: ImageMarker, index: number) => index !== imageMarkerIndex);
        this.setState((initialState) => {
            return {
                title: initialState.title,
                image: initialState.image,
                imageMarkers: updatedImageMarkers,
            };
        });
    }

    renderImageMarker(imageMarker: ImageMarker, imageMarkerIndex: number) {
        return (
            <div key={imageMarkerIndex}>
                <div onClick={this.deleteMarkerAtIndex.bind(this, imageMarkerIndex)}
                    role="button"
                    className="DeleteCross"
                    >x</div>
                {(imageMarkerIndex + 1) + ":"}
                <MultiValueInput
                    values={imageMarker.labels}
                    onUpdate={this.updateImageMarkerLabel.bind(this, imageMarkerIndex)}
                    onBlur={this.triggerBlockUpdate.bind(this)}/>
            </div>
        )
    }

    renderImageInformation() {
        if (!this.state.image.bytes) {
            return (
                <div className="BlockContent">
                    <input type="file"
                           accept="image/*"
                           onChange={this.updateImage.bind(this)} />
                </div>
            );
        } else {
            return (
                <div style={{padding: "5px"}}>
                    <canvas ref={this.drawImageWithMarkers.bind(this)}
                            onClick={this.createImageMarker.bind(this)}/>
                    {this.state.imageMarkers.map((imageMarker, imageMarkerIndex) => {
                        return this.renderImageMarker(imageMarker, imageMarkerIndex);
                    })}
                </div>
            );
        }
    }

    render() {
        return (
            <div className="BlockContent">
                <input type="text"
                       value={this.state.title}
                       placeholder="Title"
                       className="fullWidth"
                       onChange={this.updateTitle.bind(this)}
                       onBlur={this.triggerBlockUpdate.bind(this)}/>
                {this.renderImageInformation()}
            </div>
        );
    }
}

export interface MarkedImageViewProps {
    image: RawImageData;
    markers: ImageMarker[];
    showError: (error: string) => void;
}

export class MarkedImageView extends React.Component<MarkedImageViewProps> {
    drawImageWithMarkers(canvas: HTMLCanvasElement) {
        drawImageWithMarkers(
            canvas,
            this.props.image,
            this.props.markers,
            this.props.showError.bind(this),
            () => {});
    }

    render() {
        return (
            <div>
                <canvas ref={this.drawImageWithMarkers.bind(this)}/>
            </div>
        );
    }
}

function drawImageWithMarkers(canvas: HTMLCanvasElement,
                              imageData: RawImageData,
                              imageMarkers: ImageMarker[],
                              showError: (error: string) => void,
                              notifySize: (width: number, height: number) => void) {
    if (!canvas) {
        return;
    }
    const context = canvas.getContext("2d");
    if (!context) {
        showError("Failed to draw image.");
        return;
    }
    const image = new Image();
    image.src = imageData.bytes;
    image.onload = function () {
        notifySize(image.width, image.height);
        drawImageContent(canvas, context, image, imageMarkers);
    }
}

function drawLabel(context: CanvasRenderingContext2D,
                   offsetX: number,
                   offsetY: number,
                   label: number,
                   imageMarker: ImageMarker,
                   imageWidthOffset: number,
                   imageHeightOffset: number) {
    context.beginPath();
    context.lineWidth = 3;
    context.strokeStyle = "black";
    context.font = "20px Georgia";
    context.textAlign = "center"; 
    context.textBaseline = "middle";
    context.fillText("" + label, offsetX + (labelWidth / 2), offsetY + (labelHeight / 2));
    let lineStartX: number;
    if (imageMarker.position.x > offsetX) {
        lineStartX = offsetX + labelWidth;
    } else {
        lineStartX = offsetX;
    }
    context.moveTo(lineStartX, offsetY + (labelHeight / 2));
    context.lineTo(imageMarker.position.x + imageWidthOffset, imageMarker.position.y + imageHeightOffset);
    context.moveTo(offsetX, offsetY);
    context.lineTo(offsetX + labelWidth, offsetY);
    context.lineTo(offsetX + labelWidth, offsetY + labelHeight);
    context.lineTo(offsetX, offsetY + labelHeight);
    context.lineTo(offsetX, offsetY);
    context.stroke();
}

const labelWidth = 40;
const labelHeight = 40;
const labelSpacing = 5;

function drawImageContent(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, image: HTMLImageElement, imageMarkers: ImageMarker[]) {
    const imageWidthOffset = getImageWidthOffset();
    const imageHeightOffset = getImageHeightOffset(image.height, imageMarkers);
    const imageMarkersPerSide = Math.ceil(imageMarkers.length / 2);
    
    canvas.width = image.width + (2 * imageWidthOffset);
    canvas.height = image.height + (2 * imageHeightOffset);
    context.drawImage(image, imageWidthOffset, imageHeightOffset);

    let imageMarkersWithIndex = imageMarkers.map((imageMarker, imageMarkerIndex) => {
        return {
            index: imageMarkerIndex,
            imageMarker: imageMarker,
        }
    });
    const sortedImageMarkers = imageMarkersWithIndex.sort((i1, i2) => {
        return i1.imageMarker.position.x - i2.imageMarker.position.x;
    });

    const leftImageMarkers = sortedImageMarkers
        .slice(0, imageMarkersPerSide)
        .sort((i1, i2) => {
            return i1.imageMarker.position.y - i2.imageMarker.position.y;
        });
    leftImageMarkers.forEach((imageMarkerWithIndex, pos) => {
        drawLabel(
            context,
            labelSpacing,
            labelSpacing + (pos * (labelSpacing + labelWidth)),
            imageMarkerWithIndex.index + 1,
            imageMarkerWithIndex.imageMarker,
            getImageWidthOffset(),
            getImageHeightOffset(image.height, imageMarkers));
    });
    const rightImagemarkers = sortedImageMarkers
        .slice(imageMarkersPerSide)
        .sort((i1, i2) => {
            return i1.imageMarker.position.y - i2.imageMarker.position.y;
        });
    rightImagemarkers.forEach((imageMarkerWithIndex, pos) => {
        drawLabel(
            context,
            imageWidthOffset + image.width + labelSpacing,
            labelSpacing + (pos * (labelSpacing + labelWidth)),
            imageMarkerWithIndex.index + 1,
            imageMarkerWithIndex.imageMarker,
            getImageWidthOffset(),
            getImageHeightOffset(image.height, imageMarkers));
    });
}

function getImageWidthOffset() : number {
    return (labelWidth + (2 * labelSpacing));
}

function getImageHeightOffset(imageHeight: number,
                              imageMarkers: ImageMarker[]) : number {
    const imageMarkersPerSide = Math.ceil(imageMarkers.length / 2);
    const labelSpaceHeight = (labelHeight + labelSpacing);
    const imageBoxHeight = (imageMarkersPerSide * labelSpaceHeight) + labelSpaceHeight;

    if (imageBoxHeight > imageHeight) {
        return Math.ceil((imageBoxHeight - imageHeight) / 2);
    } else {
        return 0;
    }
}

export function createQuestionsForImage(block: ImageBlock,
                                        getConfidenceForBlock: (blockId: string,
                                                                questionHint: string) => Promise<number>): Promise<QuestionMeta[]> {
    const promises: Promise<QuestionMeta>[] = block.imageMarkers.map((imageMarker: ImageMarker, index: number) => {
        return getConfidenceForBlock(block.id, index.toString())
            .then((confidence) => {
                let question: MultipleChoiceQuestion | TextQuestion;
                if (TopicDatabase.IsBeginnerConfidence(confidence)) {
                    const label = pickOne(imageMarker.labels)
                    question = {
                        type: "multipleChoice",
                        id: new Date().getTime(),
                        question: "1",
                        possibleAnswers: multipleOf(block.imageMarkers.flatMap(m => m.labels), 3, [label], imageMarker.labels),
                        expectedAnswer: label,
                    }
                } else if (TopicDatabase.IsIntermediateConfidence(confidence)) {
                    const label = pickOne(imageMarker.labels)
                    question = {
                        type: "multipleChoice",
                        id: new Date().getTime(),
                        question: "1",
                        possibleAnswers: mutationsOf(label, 3),
                        expectedAnswer: label,
                    }
                } else {
                    question = {
                        type: "text",
                        id: new Date().getTime(),
                        question: "1",
                        expectedAnswers: imageMarker.labels,
                    }
                }
                return {
                    ...questionMetaDefaults,
                    question: {
                        type: "image",
                        id: new Date().getTime(),
                        title: block.title,
                        image: block.image,
                        position: imageMarker.position,
                        question: question,
                    },
                    confidence: confidence,
                    blockId: block.id,
                    questionHint: index.toString(),
                };
            });
    });
    return Promise.all(promises)
        .then((results) => {
            return results;
        });
}
