import React from "react";
import {RawImageData} from "../Data";

export interface ConfirmationDialogProps {
    question: string;
    answer_yes: () => void;
    answer_no: () => void;
}

export class ConfirmationDialog extends React.Component<ConfirmationDialogProps> {
    render() {
        return (
            <div className="App">
            {this.props.question}
            <div className="ActionBar">
                <span
                    onClick={this.props.answer_yes.bind(this)}
                    title="Confirm with yes"
                    className="ActionButton">Yes</span>
                <span
                    onClick={this.props.answer_no.bind(this)}
                    title="Confirm with no"
                    className="ActionButton">No</span>
            </div>
        </div>
        );
    }
}

interface MultiValueInputProps {
    values: string[];
    onUpdate: (values: string[]) => void;
    onBlur: () => void;
}

interface MultiValueInputState {
    values: string[];
}

export class MultiValueInput extends React.Component<MultiValueInputProps, MultiValueInputState> {
    constructor(props: MultiValueInputProps) {
        super(props);
        this.state = {
            values: props.values,
        };
    }

    onValueChanged(valueIndex: number, event: React.ChangeEvent<HTMLInputElement>) {
        const updatedValues = this.state.values;
        if (valueIndex < 0 || valueIndex >= updatedValues.length) {
            console.log("Try to access value at index ", valueIndex);
            return;
        }
        updatedValues[valueIndex] = event.target.value;
        this.setState((initialState) => {
            return {
                ...initialState,
                values: updatedValues,
            };
        });
    }

    handleDeleteValue(valueIndex: number) {
        this.setState((initialState) => {
            return {
                ...initialState,
                values: this.state.values.filter((v: string, i: number) => i !== valueIndex),
            };
        });
    }

    handleAddValue() {
        const updatedValues = this.state.values;
        updatedValues.push("");
        this.setState((initialState) => {
            return {
                ...initialState,
                values: updatedValues,
            };
        });
    }

    renderDeleteButton(value: string, index: number) {
        if (this.state.values.length <= 1) {
            return;
        }
        return (
            <div
                onClick={this.handleDeleteValue.bind(this, index)}
                role="button"
                className="ActionButton"
            >x</div>
        );
    }

    renderAddButton(index: number) {
        if (index !== this.state.values.length - 1) {
            return;
        }
        return (<div
                onClick={this.handleAddValue.bind(this)}
                role="button"
                className="ActionButton"
            >+</div>
        );
    }

    render() {
        return (
            <div>
                {this.state.values.map((value, index) => {
                    return (
                        <div key={index}>
                            <input type="text"
                                   value={value}
                                   onChange={this.onValueChanged.bind(this, index)}
                                   onBlur={this.props.onBlur}/>
                            {this.renderDeleteButton(value, index)}
                            {this.renderAddButton(index)}
                        </div>);
                })}
            </div>);
    }
}

export function cleanTextInput(input: string): string {
    return input.trim().replace("/", "|");
}

export function submitOnEnter(event: React.KeyboardEvent<HTMLInputElement>,
                              submit?: () => void | undefined) {
    if (!submit) {
        return;
    }
    if (event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        submit();
    }
}

export function drawImage(image: RawImageData) {
    if (image.bytes === "") {
        return;
    }
    return (
        <img src={image.bytes} alt={image.reference}/>
    );
}